import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { LoggerService } from "../../services/logger.service";
import { DataService } from "../../services/data.service";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { BehaviorSubject } from "rxjs";
import Decimal from "decimal.js";
import { Clipboard } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CustomizeTableColumnsComponent } from "../customize-table-columns/customize-table-columns.component";
import { TableSearchComponent } from "../table-search/table-search.component";
import { VFIconComponent } from "../vficon/vficon.component";
import { PaginationComponent } from "../pagination/pagination.component";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  ChangeEvent,
  FilterItem,
  TableCell,
  TableColumn,
  TableRow,
  TableState,
  TableStates,
} from "../../models/common-table";
import { PaginationState } from "../../models/pagination";
import { Currency } from "../../models/currency";
import { DEFAULT_PAGE_SIZE } from "../../constant/tables";
import Swal from "sweetalert2";
import { TenantService } from "../../services/tenant.service";
import { LookerStudioService } from "../../services/looker-studio.service";

const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;

type SortOrder = "ASC" | "DESC";

function defaultSerializer(row: TableRow, keys: string[]): string | null {
  if ((keys || []).length === 0) {
    return null;
  }
  return keys
    .map((heading: string, hIdx: number) => {
      const cell = row[hIdx];
      if (!cell) {
        return "";
      }
      return cell.rawValue ?? (Array.isArray(cell.value) ? cell.value[0] : cell.value);
    })
    .map((i) => i ?? "") // fetch value if present
    .map((i) => (typeof i === "string" ? i : JSON.stringify(i))) // try coerce to string
    .map((i) => i.replace(/"/g, '""')) // escape double quote
    .map((i) => (/[",]/.test(i) ? '"' + i + '"' : i)) // add double quote string identifer
    .join(",");
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    CustomizeTableColumnsComponent,
    TableSearchComponent,
    VFIconComponent,
    RouterModule,
    PaginationComponent,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    NgxMatTimepickerModule,
    FontAwesomeModule,
  ],
  selector: "app-common-table",
  templateUrl: "./common-table.component.html",
  styleUrls: ["./common-table.component.scss"],
})
export class CommonTableComponent<T extends { [key: string]: any }> implements OnChanges, OnInit, OnDestroy {
  // table data input bound
  @Input() tableName: string;

  // NOTE: the input columns do not contain info about whether
  // the column is visible or not
  // this is managed inside the component and localstorage
  @Input() columns: TableColumn[];
  @Input() rows: TableRow[];
  @Input() customGrandTotal: TableRow;
  @Input() downloadFileNamePrefix: string = "";
  @Input() disableDownload: boolean = false;

  @Input() lowerCaseInput = true;
  @Input() dynamicPagination = false;
  @Input() estimatedRows = -1;
  @Input() exactRows: number | undefined = undefined;

  @Input() loading = false;
  @Input() currency: Currency;

  @Input() showColumnSelectAccordian = true;
  @Input() showFilterAccordian = true;
  @Input() selectableRows = false;
  @Input() downloadSerializer: (row: TableRow, keys: string[]) => string = defaultSerializer;
  @Input() downloadKeys: string[] = [];
  // this should be passed in from the query parameters by the parent page ideally
  @Input() inputFilters: FilterItem[] = [];
  @Input() pageSize = DEFAULT_PAGE_SIZE;

  // table event handler
  @Output() changeEvent: EventEmitter<ChangeEvent<T>> = new EventEmitter();
  @Output() dataFiltered: EventEmitter<TableRow[]> = new EventEmitter();
  @Output() dataSorted: EventEmitter<{ data: TableRow[]; newOrder: SortOrder; currentOrder: SortOrder }> =
    new EventEmitter();
  @Output() pageChanged: EventEmitter<PaginationState<TableRow>> = new EventEmitter();
  @Output() filtersChanged: EventEmitter<FilterItem[]> = new EventEmitter();
  @Output() selectedColumns: BehaviorSubject<Partial<TableColumn>[]> = new BehaviorSubject([]);

  // class properties
  // // table
  public tableRows: TableRow[] = [];

  // // sorting
  public sortOrders: { [colName: string]: SortOrder } = {};

  // // pagination
  public currentPage: TableRow[] = [];
  public pageNumber = 0;

  // // helpers
  public keys = Object.keys;
  public isArray = Array.isArray;

  public backgroundColors = {
    warning: "rgb(255, 255, 0, 0.2)",
    success: "rgb(144, 238, 144, 0.2)",
    danger: "rgb(250, 0, 0, 0.2)",
    default: "auto",
  };

  private timeouts: number[] = [];
  public faStop = faCaretDown;
  public faStart = faCaretUp;
  public findColumnValueTotal: boolean = false;
  public computeMomTrend: boolean = false;

  constructor(
    private log: LoggerService,
    private dataService: DataService,
    private clipboard: Clipboard,
    private tenant: TenantService,
    private looker: LookerStudioService
  ) {}

  ngOnDestroy() {
    this.timeouts.forEach((s) => clearTimeout(s));
  }

  ngOnInit(): void {
    const state = this.getTableState();

    this.log.info("found state in localstorage:", state);

    this.pageSize = state.pageSize ?? this.pageSize;
    this.pageNumber = state.pageNumber ?? this.pageNumber;

    this.pageChanged.next({
      pageSize: state.pageSize ?? this.pageSize,
      pageNumber: state.pageNumber ?? this.pageNumber,
    });

    if (this.inputFilters.length === 0) {
      this.inputFilters = state.filterItems;
      this.filtersChanged.next(state.filterItems);
    }

    if ((state.selectedColumns || []).length > 0) {
      for (const { selected, title } of state.selectedColumns) {
        const column = this.columns.find((c) => c.title === title);
        if (column && selected !== undefined) {
          column.selected = selected;
        }
      }
      this.selectedColumns.next(this.columns);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.log.info("common table changes", changes);

    if (changes.columns) {
      const state = this.getTableState();
      let selectedColumns = state.selectedColumns || [];
      if (selectedColumns.length === 0) {
        selectedColumns = changes.columns.currentValue;
      }
      selectedColumns = selectedColumns.map(({ selected, ...c }) => ({
        ...c,
        selected: selected === undefined ? true : selected,
      }));
      for (const { title, selected } of selectedColumns) {
        const column = this.columns.find((c) => c.title === title);
        if (column) {
          column.selected = selected;
        }
      }
      for (const c of this.columns) {
        if (c.sum) this.findColumnValueTotal = true;
        if (c.computeMomTrend) this.computeMomTrend = true;
      }
    }

    if (changes.inputFilters || changes.rows) {
      const state = this.getTableState();
      const tableStates = JSON.parse(localStorage.getItem("tableStates"));
      const url: string = this.getUrl();
      this.log.info("detected changes to data or filters");
      const currentRowCount = (this.tableRows || []).length;
      this.tableRows = this.getFilteredData(this.rows, this.columns, this.inputFilters);
      const newRowCount = (this.tableRows || []).length;

      if (currentRowCount > newRowCount) {
        this.pageNumber = 0;
        this.setTableStates({ pageNumber: this.pageNumber });
      }

      if (changes.inputFilters && !changes.inputFilters.firstChange) {
        this.setTableStates({ filterItems: this.inputFilters });
      }
      if (tableStates[url][this.tableName].pageSize * tableStates[url][this.tableName].pageNumber > 5000) {
        this.setTableStates({ pageNumber: 0 });
      }
    }
  }

  public extractUrl(tooltip?: string): string | undefined {
    const match = URL_REGEX.exec(tooltip || "");
    if (match && match.length > 0 && match[0].length > 0) {
      return match[0];
    }
  }

  public handleColumnTooltipClick(tooltip?: string): void {
    this.log.debug("handling click on tooltip:", tooltip);
    const url = this.extractUrl(tooltip);
    if (url) {
      this.log.info("opening:", url);
      window.open(url, "_blank");
    }
  }

  public trackByColumn(index: number, colum: TableColumn): string {
    return colum.label || colum.title;
  }

  public notifyChange(event: UIEvent, data: T, column: string, options: { [key: string]: any } = null): void {
    this.log.info({ msg: "change event", event, data, column, options });

    try {
      this.log.info({
        currentValue: data[column],
        // @ts-ignore
        newValue: event.target.value,
      });
    } catch (e) {}

    this.changeEvent.next({ event, data, column, options });
  }

  // customize table columns handler
  public handleColumnSelection(updatedColumns: Partial<TableColumn>[]): void {
    this.log.info("handling column change:", updatedColumns);
    for (const column of updatedColumns) {
      const c = this.columns.find((c) => c.title === column.title);
      if (c.selected !== column.selected) {
        this.log.info("column ", c.title, c.label, column.selected ? "selected" : "de-selected");
        c.selected = column.selected;
      }
    }
    this.setTableStates({ selectedColumns: this.columns });
    this.selectedColumns.next(this.columns);
  }

  public handlePaginationChange(state: PaginationState<TableRow>): void {
    this.log.info("handling pagination change:", state);
    const { pageNumber: pageNumber, pageSize, currentPage } = state;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.timeouts.push(
      setTimeout(() => {
        this.currentPage = currentPage;
      }) as unknown as number
    );
    this.setTableStates({ pageNumber, pageSize });
    this.pageChanged.next(state);
  }

  private getRowSorter(columnIdx: number, order: SortOrder = "ASC") {
    return (r1: TableRow, r2: TableRow) => {
      let v1 = (r1[columnIdx] || {}).rawValue ?? (r1[columnIdx] || {}).selected ?? (r1[columnIdx] || {}).value ?? 0;
      let v2 = (r2[columnIdx] || {}).rawValue ?? (r2[columnIdx] || {}).selected ?? (r2[columnIdx] || {}).value ?? 0;
      if (order === "DESC") {
        // swap values
        const tmp = v2;
        v2 = v1;
        v1 = tmp;
      }
      if (!v1 || v1 === "unknown" || v1 === "na") {
        return -1;
      } else if (!v2 || v2 === "unknown" || v2 === "na") {
        return 1;
      } else if (v1 < v2) {
        return -1;
      } else if (v1 === v2) {
        return 0;
      } else {
        return 1;
      }
    };
  }

  public sortDataByColumn(column: TableColumn, columnIdx: number): void {
    this.log.info("sort on ", column, columnIdx);
    const currentOrder = this.sortOrders[column.title];

    let newOrder: SortOrder;

    if (!currentOrder) {
      newOrder = "ASC";
    } else if (currentOrder === "ASC") {
      newOrder = "DESC";
    } else if (currentOrder === "DESC") {
      newOrder = "ASC";
    }

    this.tableRows = [...this.tableRows.sort(this.getRowSorter(columnIdx, newOrder))];

    this.sortOrders[column.title] = newOrder;

    this.dataSorted.next({
      currentOrder,
      newOrder,
      data: this.tableRows,
    });
  }

  private getFilteredData(rows: TableRow[], columns: TableColumn[], filterItems: Array<FilterItem>): Array<TableRow> {
    this.log.info("filtering data with:", filterItems);

    let foundData: TableRow[];
    if (filterItems.length === 0) {
      foundData = [...rows];
    } else {
      foundData = rows.filter((row) => {
        return !filterItems.some((filterItem) => {
          const columnIdx = columns.findIndex(
            (column) => (column.label || column.title) === (filterItem.columnLabel || filterItem.columnTitle)
          );
          let cells: TableCell<any>[];

          if ((filterItem.columnLabel || filterItem.columnTitle).toUpperCase() === "ALL") {
            cells = row;
          } else if (columnIdx >= 0) {
            cells = [row[columnIdx]];
          } else {
            cells = [];
          }

          return !cells.some((cell) =>
            filterItem.searchText
              .map((s) => s.toLowerCase())
              .some((filterText) => {
                if (cell) {
                  const value = cell.selected || cell.value;
                  if (value) {
                    const s = value.toString().toLowerCase();
                    if (s) {
                      return s.includes(filterText);
                    }
                  }
                }
                return false;
              })
          );
        });
      });
    }
    this.log.info("filtered data", foundData);
    this.dataFiltered.next(foundData);
    return foundData;
  }

  private initTableStates(updates: Partial<TableState> = {}): void {
    this.log.info("intialising table state in localstorage, updates:", updates);

    const url: string = this.getUrl();

    let tableStates: TableStates;

    try {
      tableStates = JSON.parse(localStorage.getItem("tableStates"));
    } catch (e) {
      localStorage.removeItem("tableStates");
      this.log.error(e);
    }

    const tableState: TableState = {
      pageSize: this.pageSize,
      pageNumber: 0,
      filterItems: [],
      selectedColumns: this.selectedColumns.value,
    };

    // if either filter or page filter doesn't exist
    if (!tableStates) {
      tableStates = {
        [url]: {
          [this.tableName]: tableState,
        },
      };
    } else if (!tableStates[url]) {
      tableStates[url] = {
        [this.tableName]: tableState,
      };
    } else if (!tableStates[url][this.tableName]) {
      tableStates[url][this.tableName] = tableState;
    }

    this.setTableStates(updates, tableStates);
  }

  public getTableState(): TableState {
    this.log.info("getting table states");
    try {
      const states = localStorage.getItem("tableStates");
      const url: string = this.getUrl();
      const tableStates: TableStates = JSON.parse(states) ?? {};
      const result = (tableStates[url] ?? {})[this.tableName];
      if (result !== undefined) {
        return result;
      }
    } catch (e) {
      this.log.error(e);
    }
    this.initTableStates();
    return this.getTableState();
  }

  public setTableStates(updates: Partial<TableState>, tableStates?: TableStates): void {
    const url: string = this.getUrl();

    this.log.info("setting table states for:", url, "to be:", updates);

    if (!tableStates) {
      try {
        tableStates = JSON.parse(localStorage.getItem("tableStates"));
      } catch (e) {
        localStorage.removeItem("tableStates");
        this.log.error(e);
      }
    }

    if (tableStates) {
      try {
        Object.assign(tableStates[url][this.tableName], updates);
        localStorage.setItem("tableStates", JSON.stringify(tableStates));
      } catch (e) {
        this.log.error(e);
        this.initTableStates();
      }
    }
  }

  private getUrl(): string {
    const url = `${location.pathname}${location.hash}`;
    if (url === "/dashboard#services" || url === "/dashboard#tenancies") {
      return "/dashboard#change-approvals";
    } else {
      return url;
    }
  }

  public handleFiltersChange(filterItems: FilterItem[]) {
    this.log.info("new filters", filterItems);
    this.inputFilters = filterItems;
    this.filtersChanged.next(filterItems);
  }

  public columnTotal(index: number): string {
    let sum = new Decimal(0);
    for (const r of this.tableRows || []) {
      const val = r[index]?.rawValue ?? 0;
      if (val !== 0) {
        sum = sum.add(val);
      }
    }
    if (this.currency) {
      return this.currency?.format?.format(this.currency.exchangeRate.mul(sum).toDecimalPlaces(2).toNumber());
    } else {
      return this.looker.formatNumber(sum.toNumber()).fmt;
    }
  }

  public columnMomTrend(index: number): string {
    let currentSum = new Decimal(0);
    let compareSum = new Decimal(0);

    for (const r of this.tableRows) {
      const val = r[index].data.Value[0];
      const val2 = r[index].data.Value[1];
      if (val !== 0) {
        currentSum = currentSum.add(val);
      }
      if (val2 !== 0) {
        compareSum = compareSum.add(val2);
      }
    }

    let momTrend = compareSum.minus(currentSum).dividedBy(currentSum).mul(100);
    return new Intl.NumberFormat().format(momTrend.toDecimalPlaces(2).toNumber());
  }

  public async download(type: string): Promise<void> {
    let headers: string[] = this.columns.map((column) => column.label);
    let rowKeys: string[] = this.columns.map((column) => column.title);
    if (this.selectableRows) {
      headers = headers.slice(1);
      rowKeys = rowKeys.slice(1);
    }

    if (this.downloadKeys.length !== 0) {
      rowKeys = this.downloadKeys;
      headers = this.downloadKeys;
    }
    let deselectedColumns: TableColumn[] = this.columns.filter((column) => !column.selected && column.title !== "");
    if (deselectedColumns.length > 0) {
      const dowloadConsent = await Swal.fire({
        title: "Do you want to download selected columns?",
        confirmButtonColor: this.tenant.colors.primary,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        backdrop: false,
      });
      if (dowloadConsent.isConfirmed) {
        headers = headers.filter((column, i) => this.columns[i]["selected"]);
        rowKeys = rowKeys.filter((column, i) => this.columns[i]["selected"]);
        type = "selectedColumns";
      }
    }

    this.log.info(headers);
    const headings = headers.join(",");
    this.log.info(this.rows);
    let rowsToSave: TableRow[];
    this.log.info(`Saving ${type} rows`);
    switch (type) {
      case "all":
        rowsToSave = this.rows;
        break;
      case "filtered":
        rowsToSave = this.tableRows;
        break;
      case "selected":
        rowsToSave = this.rows.filter((row) => row[0].value == true);
        break;
      case "selectedColumns":
        rowsToSave = this.rows.map((row) => {
          return row.filter((r, i) => this.columns[i]["selected"]);
        });
        break;
      default:
        this.log.info("Invalid save type configured, please add to common table component if needed");
        return;
    }
    this.log.debug(rowsToSave);

    const csvRows: Array<string> = [headings];

    for (const row of rowsToSave) {
      this.log.info("Row to be serialized:", row);
      this.log.info("Column keys to write to csv: ", rowKeys);
      const serialisedRow = this.downloadSerializer(this.selectableRows ? row.slice(1) : row, rowKeys);
      if (serialisedRow !== null) {
        csvRows.push(serialisedRow);
      }
    }
    this.log.info("Serialized row to write to csv: ", csvRows);
    let prefix: string = this.tableName;
    if (this.downloadFileNamePrefix != "") {
      prefix = this.downloadFileNamePrefix;
    }
    const downloadFileName = `${prefix}-${new Date().toISOString()}.csv`;

    this.dataService.download(csvRows.join("\n"), downloadFileName);
  }

  public copyValue(value: string): void {
    if (value) {
      this.clipboard.copy(value.toString());
    }
  }
}
