import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/guard/guard.module";

import { DEFAULT_SECTION } from "./shared/constant/tenancies";

const routes: Routes = [
  {
    path: "chart-playground",
    loadComponent: () =>
      import("./shared/component/interactive-chart-section/interactive-chart-section.component").then(
        (m) => m.InteractiveChartSectionComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [{ url: "/dashboard", text: "Dashboard" }],
  },
  {
    path: "cost-comparison/compute",
    loadComponent: () =>
      import("./home/cost-comparison/compute-service/compute-service.component").then((m) => m.ComputeServiceComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", fragment: "costcomparison", text: "Cost Comparison" },
    ],
  },
  {
    path: "",
    loadComponent: () => import("./home/login/login.component").then((m) => m.LoginComponent),
    children: [
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "login",
    loadComponent: () => import("./home/login/login.component").then((m) => m.LoginComponent),
    pathMatch: "full",
  },
  {
    path: "login/form",
    loadComponent: () => import("./home/login/login.component").then((m) => m.LoginComponent),
    pathMatch: "full",
  },

  //  START VCLOUDSMART ROUTES
  {
    path: "vcloudsmart",
    loadComponent: () =>
      import("./home/looker-studio-report/looker-studio-report.component").then((m) => m.LookerStudioReportComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Unassociated IP Addresses",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-unassociated-ip-addresses/aws-unassociated-ip-addresses.component").then(
        (m) => m.AwsUnassociatedIpAddressesComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/AWS Cost Anomaly Detection",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-cost-anomaly-detection/aws-cost-anomaly-detection.component").then(
        (m) => m.AwsCostAnomalyDetectionComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Cost by On-Demand Compute",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-cost-by-on-demand-compute/aws-cost-by-on-demand-compute.component").then(
        (m) => m.AwsCostByOnDemandComputeComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Savings Plans",
    loadComponent: () =>
      import("./home/vcloudsmart/savings-plan/savings-plan.component").then((m) => m.SavingsPlanComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Overview",
    loadComponent: () => import("./home/vcloudsmart/overview/overview.component").then((m) => m.OverviewComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Overview",
    loadComponent: () => import("./home/vcloudsmart/gcp/overview/overview.component").then((m) => m.OverviewComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/:csp/:section/Buckets",
    loadComponent: () => import("./home/vcloudsmart/oci/buckets/buckets.component").then((m) => m.BucketsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
      { url: "/dashboard", text: "Buckets", fragment: "vcloudsmart/oci/buckets" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/FinOps/Overview",
    loadComponent: () => import("./home/vcloudsmart/oci/overview/overview.component").then((m) => m.OverviewComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Forecasts",
    loadComponent: () => import("./home/vcloudsmart/gcp/forecast/forecast.component").then((m) => m.ForecastComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Forecasts",
    loadComponent: () => import("./home/vcloudsmart/forecast/forecast.component").then((m) => m.ForecastComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/Budgets/Budgets Overview",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-budgets/overview/aws-budgets-overview.component").then(
        (m) => m.AWSBudgetsOverviewComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/Budgets/Budgets Variance",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-budgets/variance/aws-budgets-variance.component").then(
        (m) => m.AWSBudgetsVarianceComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/EC2 Instances",
    loadComponent: () => import("./home/vcloudsmart/aws-csb/aws-csb-page.component").then((m) => m.AwsCsbPageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Terminated EC2 Instances",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-csb-terminated-instances/aws-csb-terminated-instances.component").then(
        (m) => m.AwsCsbTerminatedInstancesComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/Asset Inventory (CSB)/S3 Storage Buckets",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-csb-storage-buckets/aws-csb-storage-buckets.component").then(
        (m) => m.AwsCsbStorageBucketsComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Charge Analysis (SAMS)",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-vams/aws-vams-page.component").then((m) => m.AwsVamsPageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Cost Governance",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-governance/cost-governance.component").then((m) => m.CostGovernanceComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
    children: [
      { path: "", redirectTo: "overview", pathMatch: "full" },
      {
        path: "overview",
        loadComponent: () =>
          import("./home/vcloudsmart/cost-governance/overview/overview.component").then((m) => m.OverviewComponent),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
        ],
      },
      {
        path: "monthly-saving-by-accounts",
        loadComponent: () =>
          import(
            "./home/vcloudsmart/cost-governance/monthly-savings-by-account/monthly-savings-by-account.component"
          ).then((m) => m.MonthlySavingsByAccountComponent),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
        ],
      },
      {
        path: "monthly-saving-by-savings",
        loadComponent: () =>
          import(
            "./home/vcloudsmart/cost-governance/monthly-savings-by-savings/monthly-savings-by-savings.component"
          ).then((m) => m.MonthlySavingsBySavingsComponent),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
        ],
      },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/EKS Recommendations",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-eks-recommendations/aws-eks-recommendations-page.component").then(
        (m) => m.AwsEksRecommendationsPageComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/:section/Assets Overview",
    loadComponent: () => import("./home/vcloudsmart/oci/assets/assets.component").then((m) => m.AssetsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/:section/Storage Volumes",
    loadComponent: () => import("./home/vcloudsmart/oci/volumes/volumes.component").then((m) => m.VolumesComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/:section/Compute Instances",
    loadComponent: () =>
      import("./home/vcloudsmart/oci/compute-instance/compute-instance.component").then(
        (m) => m.ComputeInstanceComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/:section/Database Systems",
    loadComponent: () =>
      import("./home/vcloudsmart/oci/db-system/db-system.component").then((m) => m.DbSystemComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/EC2 Instance Rightsizing",
    loadComponent: () =>
      import("./home/vcloudsmart/ec2-instance-rightsizing/ec2-instance-rightsizing.component").then(
        (m) => m.Ec2InstanceRightsizingComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/:section/Cost Analysis/:subreport",
    loadComponent: () => import("./home/vcloudsmart/oci/reports/reports.component").then((m) => m.ReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/FinOps/Cost Analysis",
    redirectTo: "/vcloudsmart/:version/oci/FinOps/Cost Analysis/Cost per Compartment",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/oci/:section/File Systems",
    loadComponent: () =>
      import("./home/vcloudsmart/oci/file-system/file-system.component").then((m) => m.FileSystemComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/:section/Virtual Networks",
    loadComponent: () => import("./home/vcloudsmart/oci/networks/networks.component").then((m) => m.NetworksComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Inventory",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-assets/aws-assets-page.component").then((m) => m.AwsAssetsPageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },

  {
    path: "vcloudsmart/:version/evo/:section/Billing and Spend Management",
    loadComponent: () =>
      import("./home/vcloudsmart/evo-dashboard/billing-and-spend/billing-and-spend.component").then(
        (m) => m.BillingAndSpendComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
    ],
    children: [
      { path: "", redirectTo: "spend", pathMatch: "full" },
      {
        path: "spend",
        loadComponent: () =>
          import("./home/vcloudsmart/evo-dashboard/billing-and-spend/spend/spend.component").then(
            (m) => m.SpendComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
        ],
      },
      {
        path: "cuds",
        loadComponent: () =>
          import("./home/vcloudsmart/evo-dashboard/billing-and-spend/cuds/cuds.component").then((m) => m.CudsComponent),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
        ],
      },
      {
        path: "on-demand",
        loadComponent: () =>
          import("./home/vcloudsmart/evo-dashboard/billing-and-spend/on-demand/on-demand.component").then(
            (m) => m.OnDemandComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
        ],
      },
      {
        path: "machine",
        loadComponent: () =>
          import("./home/vcloudsmart/evo-dashboard/billing-and-spend/machine/machine.component").then(
            (m) => m.MachineComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
        ],
      },
    ],
  },

  {
    path: "vcloudsmart/:version/evo/:section/CUD Utilization",
    loadComponent: () =>
      import("./home/vcloudsmart/evo-dashboard/cud-utilization/cud-utilization.component").then(
        (m) => m.CudUtilizationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
    ],
    children: [
      { path: "", redirectTo: "compute", pathMatch: "full" },
      {
        path: "compute",
        loadComponent: () =>
          import("./home/vcloudsmart/evo-dashboard/cud-utilization/compute/compute.component").then(
            (m) => m.ComputeComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
        ],
      },
      {
        path: "ram",
        loadComponent: () =>
          import("./home/vcloudsmart/evo-dashboard/cud-utilization/ram/ram.component").then((m) => m.RamComponent),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
        ],
      },
      {
        path: "sles",
        loadComponent: () =>
          import("./home/vcloudsmart/evo-dashboard/cud-utilization/sles/sles.component").then((m) => m.SlesComponent),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
          { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
        ],
      },
    ],
  },
  {
    path: "vcloudsmart/:version/evo/:section/CUD View",
    loadComponent: () =>
      import("./home/vcloudsmart/evo-dashboard/cud-view/cud-view.component").then((m) => m.CudViewComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
    ],
  },
  {
    path: "vcloudsmart/:version/evo/:section/Spend by Service view",
    loadComponent: () =>
      import("./home/vcloudsmart/evo-dashboard/spend-by-service/spend-by-service-day-view.component").then(
        (m) => m.SpendByServiceDayViewComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
    ],
  },
  {
    path: "vcloudsmart/:version/evo/:section/Reservation By Project",
    loadComponent: () =>
      import("./home/vcloudsmart/evo-dashboard/reservation-by-project/reservation-by-project.component").then(
        (m) => m.ReservationByProjectComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "EVO", fragment: "vcloudsmart/evo" },
    ],
  },
  {
    path: "vcloudsmart/:version/:csp/:section/Executive Dashboard",
    loadComponent: () =>
      import("./home/vcloudsmart/executive-dashboard-page/executive-dashboard-page.component").then(
        (m) => m.ExecutiveDashboardPageComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: ":CSP", fragment: "vcloudsmart/:csp" },
    ],
    children: [
      { path: "", redirectTo: "billing", pathMatch: "full" },
      {
        path: "billing",
        loadComponent: () =>
          import("./home/vcloudsmart/executive-dashboard-page/billing/billing.component").then(
            (m) => m.BillingComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
        ],
      },
      {
        path: "mom-trend",
        loadComponent: () =>
          import("./home/vcloudsmart/executive-dashboard-page/mom-trend/mom-trend.component").then(
            (m) => m.MoMTrendComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
        ],
      },
      {
        path: "compute",
        loadComponent: () =>
          import("./home/vcloudsmart/executive-dashboard-page/compute/compute.component").then(
            (m) => m.ComputeComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
        ],
      },
      {
        path: "storage",
        loadComponent: () =>
          import("./home/vcloudsmart/executive-dashboard-page/storage/storage.component").then(
            (m) => m.StorageComponent
          ),
        data: [
          { url: "/dashboard", text: "Dashboard" },
          { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
        ],
      },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/:section/Cost Breakdown",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/cost-breakdown/cost-breakdown.component").then((m) => m.CostBreakdownComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },

  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Programme",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/reports-gcp/reports-gcp.component").then((m) => m.ReportsGcpComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "patch-mgmt/aws",
    redirectTo: "/patch-mgmt/aws/regions/eu-west-1/deployments/ALL",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost per Programme",
    redirectTo: "/vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Programme",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Service",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/reports-gcp/reports-gcp.component").then((m) => m.ReportsGcpComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Billing Account",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/reports-gcp/reports-gcp.component").then((m) => m.ReportsGcpComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Project",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/reports-gcp/reports-gcp.component").then((m) => m.ReportsGcpComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Cost Centre",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/reports-gcp/reports-gcp.component").then((m) => m.ReportsGcpComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },

  {
    path: "vcloudsmart/:version/gcp/SAMS (VAMS)/Other Org. & Support Charges/Other Organization Costs",
    loadComponent: () =>
      import(
        "./home/vcloudsmart/gcp/other-organisation-and-support-charges/other-organisation-and-support-charges.component"
      ).then((m) => m.OtherOrganisationAndSupportChargesComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/SAMS (VAMS)/Other Org. & Support Charges/Support",
    loadComponent: () =>
      import(
        "./home/vcloudsmart/gcp/other-organisation-and-support-charges/other-organisation-and-support-charges.component"
      ).then((m) => m.OtherOrganisationAndSupportChargesComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/SAMS (VAMS)/Other Org. & Support Charges/Billing Accounts",
    loadComponent: () =>
      import(
        "./home/vcloudsmart/gcp/other-organisation-and-support-charges/other-organisation-and-support-charges.component"
      ).then((m) => m.OtherOrganisationAndSupportChargesComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Environment",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/reports-gcp/reports-gcp.component").then((m) => m.ReportsGcpComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Local Market",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/reports-gcp/reports-gcp.component").then((m) => m.ReportsGcpComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },

  {
    path: "vcloudsmart/:version/aws/FinOps/Cost Analysis/Cost Table (Cost per Account)",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-table-page/cost-table-page.component").then((m) => m.CostTablePageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Cost Analysis/Cost per Organisation",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-table-page/cost-table-page.component").then((m) => m.CostTablePageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Cost Analysis/Cost per Environment",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-table-page/cost-table-page.component").then((m) => m.CostTablePageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Cost Analysis/Cost per Product",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-table-page/cost-table-page.component").then((m) => m.CostTablePageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Cost Analysis/Cost per Master Account",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-table-page/cost-table-page.component").then((m) => m.CostTablePageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Cost Analysis/Reports (Cost per Cost Centre)",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-table-page/cost-table-page.component").then((m) => m.CostTablePageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/EC2 Instances (State)",
    redirectTo: "/vcloudsmart/:version/aws/:section/EC2 Instances",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/aws/:section/EC2 Instances (Images)",
    redirectTo: "/vcloudsmart/:version/aws/:section/EC2 Instances",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/aws/:section/EC2 Instances (Zone)",
    redirectTo: "/vcloudsmart/:version/aws/:section/EC2 Instances",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/aws/:section/EC2 Instances (Monitoring)",
    redirectTo: "/vcloudsmart/:version/aws/:section/EC2 Instances",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/aws/:section/EC2 Instances (Account)",
    redirectTo: "/vcloudsmart/:version/aws/:section/EC2 Instances",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost by Label (Dist. per product)",
    redirectTo: "/vcloudsmart/:version/gcp/FinOps/Cost by Label",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost by Label (Dist. per project)",
    redirectTo: "/vcloudsmart/:version/gcp/FinOps/Cost by Label",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Table",
    redirectTo: "/vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Project",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost per Programme",
    redirectTo: "/vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Programme",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost Analysis",
    redirectTo: "/vcloudsmart/:version/gcp/FinOps/Cost Analysis/Cost per Project",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Cost Analysis",
    redirectTo: "/vcloudsmart/:version/aws/FinOps/Cost Analysis/Cost Table %28Cost per Account%29",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/aws/:section/Reserved Instances Recommendations",
    redirectTo:
      "vcloudsmart/:version/aws/:section/Reserved Instances Recommendations/All/3 YEARS/All_Upfront/Linked/30",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/aws/:section/Reserved Instances Recommendations/:service/:term/:paymentOption/:scope/:lookbackPeriod",
    loadComponent: () =>
      import(
        "./home/vcloudsmart/aws-reserved-instances-recommendations/aws-reserved-instances-recommendations-page.component"
      ).then((m) => m.AwsReservedInstancesRecommendationsPageComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/FinOps/Cost per Master Account (Breakdown)",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-breakdown/cost-breakdown.component").then((m) => m.CostBreakdownComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Cost by Resource Tags",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-by-resource-tags-page/cost-by-resource-tags-page.component").then(
        (m) => m.CostByResourceTagsPageComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  /* {
    path: "vcloudsmart/:version/aws/:section/Cost per Product",
    loadComponent: () =>
      import("./home/vcloudsmart/cost-per-product/cost-per-product.component").then((m) => m.CostPerProductComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  }, */
  {
    path: "vcloudsmart/:version/aws/:section/RDS Databases",
    loadComponent: () =>
      import("./home/vcloudsmart/rds-instances/rds-instances.component").then((m) => m.RdsInstancesComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/BigQuery Cost Analysis",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/bigquery-cost-analysis/big-query-cost-analysis-page.component").then(
        (m) => m.BigQueryCostAnalysisPageComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Budgets/Budgets Overview",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/budgets/budgets-overview/budgets-overview.component").then(
        (m) => m.BudgetsOverviewComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Budgets/Budgets Variance",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/budgets/budgets-variance/budgets-variance.component").then(
        (m) => m.BudgetsVarianceComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/:section/Other Org. & Support Charges",
    redirectTo: "vcloudsmart/:version/gcp/:section/Other Org. & Support Charges/Other Organization Costs",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/:section/SAMS Report",
    loadComponent: () => import("./home/vcloudsmart/gcp/gcp-vams/gcp-vams.component").then((m) => m.GcpVamsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/:section/SAMS Report",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/azure-vams/azure-vams.component").then((m) => m.AzureVamsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:csp/:section/:page",
    loadComponent: () =>
      import("./home/looker-studio-report/looker-studio-report.component").then((m) => m.LookerStudioReportComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: ":CSP", fragment: "vcloudsmart/:csp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/FinOps/Cost by Compute Instance Runtime",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/cost-by-compute-runtime/cost-by-compute-runtime.component").then(
        (m) => m.CostByComputeRuntimeComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/SAMS (VAMS)/Billing Account",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/billing-account/billing-account.component").then((m) => m.BillingAccountComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },

  {
    path: "vcloudsmart/:version/azure/Recommendation/Overview",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/recommendations/recommendations.component").then(
        (m) => m.RecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/Recommendation/Cost",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/recommendations/recommendations.component").then(
        (m) => m.RecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/Recommendation/Performance",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/recommendations/recommendations.component").then(
        (m) => m.RecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/Recommendation/Security",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/recommendations/recommendations.component").then(
        (m) => m.RecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/Recommendation/Operational Excellence",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/recommendations/recommendations.component").then(
        (m) => m.RecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/Recommendation/High Availability",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/recommendations/recommendations.component").then(
        (m) => m.RecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },

  {
    path: "vcloudsmart/:version/gcp/Recommendation/Overview",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/recommendations/recommendations.component").then((m) => m.RecommendationComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Cost",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/recommendations/recommendations.component").then((m) => m.RecommendationComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Security",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/recommendations/recommendations.component").then((m) => m.RecommendationComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Performance",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/recommendations/recommendations.component").then((m) => m.RecommendationComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Reliability",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/recommendations/recommendations.component").then((m) => m.RecommendationComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Overview",
    redirectTo: "/vcloudsmart/:version/gcp/Recommendation/Overview",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Cost",
    redirectTo: "/vcloudsmart/:version/gcp/Recommendation/Overview/Cost",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Security",
    redirectTo: "/vcloudsmart/:version/gcp/Recommendation/Overview/Security",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Performance",
    redirectTo: "/vcloudsmart/:version/gcp/Recommendation/Overview/Performance",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/gcp/Recommendation/Reliability",
    redirectTo: "/vcloudsmart/:version/gcp/Recommendation/Overview/Reliability",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Overview",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/overview/overview.component").then((m) => m.OverviewComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Per Subscription",
    redirectTo: "/vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Subscription",
    pathMatch: "full",
  },
  {
    path: "vcloudsmart/:version/azure/Asset Inventory (CSB)/Inventory",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/inventory/inventory.component").then((m) => m.InventoryComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AZURE", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/Other/SKU Discount",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/sku-discount/sku-discount.component").then((m) => m.SkuDiscountComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AZURE", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Asset Inventory (CSB)/Overview",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/assets-overview/assets-overview.component").then((m) => m.AssetsOverviewComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/Cloud Economics/Purchased Reservations",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/purchased-reservation/purchased-reservation.component").then(
        (m) => m.PurchasedReservationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AZURE", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Cost Savings by PCS Scheduler",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-cost-saving-by-scheduler/aws-cost-saving-by-scheduler.component").then(
        (m) => m.AwsCostSavingBySchedulerComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/aws/:section/Underutilized EBS Volumes",
    loadComponent: () =>
      import("./home/vcloudsmart/aws-unused-ebs-volumes/aws-unused-ebs-volumes.component").then(
        (m) => m.AwsUnderutilizedEBSVolumesComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "VCloudSmart (Billing)", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "AWS", fragment: "vcloudsmart/aws" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/Asset Inventory (CSB)/Compute Instances",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/csb-compute-instances/csb-compute-instances.component").then(
        (m) => m.CsbComputeInstancesComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/gcp/:section/Cost by Label",
    loadComponent: () =>
      import("./home/vcloudsmart/gcp/cost-by-label/cost-by-label.component").then((m) => m.CostByLabelComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "GCP", fragment: "vcloudsmart/gcp" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/Recommendations/Overview",
    loadComponent: () =>
      import("./home/vcloudsmart/oci/recommendation/recommendation.component").then(
        (m) => m.OCIRecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/Recommendations/Cost",
    loadComponent: () =>
      import("./home/vcloudsmart/oci/recommendation/recommendation.component").then(
        (m) => m.OCIRecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/Recommendations/Performance",
    loadComponent: () =>
      import("./home/vcloudsmart/oci/recommendation/recommendation.component").then(
        (m) => m.OCIRecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/oci/Recommendations/Availablity",
    loadComponent: () =>
      import("./home/vcloudsmart/oci/recommendation/recommendation.component").then(
        (m) => m.OCIRecommendationComponent
      ),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "OCI", fragment: "vcloudsmart/oci" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Subscription",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/reports/reports.component").then((m) => m.CostAnalysisReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Resource Group",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/reports/reports.component").then((m) => m.CostAnalysisReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Resource Type",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/reports/reports.component").then((m) => m.CostAnalysisReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Cost Centre",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/reports/reports.component").then((m) => m.CostAnalysisReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Cost Center",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/reports/reports.component").then((m) => m.CostAnalysisReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Service",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/reports/reports.component").then((m) => m.CostAnalysisReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Region",
    loadComponent: () =>
      import("./home/vcloudsmart/azure/reports/reports.component").then((m) => m.CostAnalysisReportsComponent),
    canActivate: [AuthGuard],
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
      { url: "/dashboard", text: "Azure", fragment: "vcloudsmart/azure" },
    ],
  },
  {
    path: "vcloudsmart/:version/azure/FinOps/Cost Analysis",
    redirectTo: "/vcloudsmart/:version/azure/FinOps/Cost Analysis/Cost per Cost Center",
    pathMatch: "full",
  },
  // END OF VCLOUDSMART ROUTES

  {
    path: "service-metrics",
    loadComponent: () =>
      import("./home/service-metrics/service-metrics.component").then((m) => m.ServiceMetricsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/service-metrics", text: "Service Metrics" },
    ],
  },
  {
    path: "service-usage",
    loadComponent: () => import("./home/service-facts/service-facts.component").then((m) => m.ServiceFactsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/service-usage", text: "Service Usage" },
    ],
  },
  {
    path: "api-credentials",
    loadComponent: () =>
      import("./home/api-credentials/api-credentials.component").then((m) => m.ApiCredentialsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/apis", text: "API Explorer" },
      { url: "/api-credentials", text: "API Credentials" },
    ],
  },
  {
    path: "api-users",
    loadComponent: () => import("./home/api-users/api-users.component").then((m) => m.ApiUsersComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/apis", text: "API Explorer" },
      { url: "/api-users", text: "API Users" },
    ],
  },
  {
    path: "api-users/:tenancy",
    loadComponent: () => import("./home/api-users/api-users.component").then((m) => m.ApiUsersComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/apis", text: "API Explorer" },
      { url: "/api-users", text: "API Users" },
      { url: "/api-users/:tenancy", text: ":tenancy" },
    ],
  },
  {
    path: "sox/:csp",
    loadComponent: () => import("./home/gcpsox-report/sox.component").then((m) => m.SoxComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/sox/:csp", text: ":CSP" },
    ],
  },
  {
    path: "api-usage",
    loadComponent: () => import("./home/api-usage/api-usage.component").then((m) => m.ApiUsageComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/apis", text: "API Explorer" },
      { url: "/api-usage", text: "API Usage" },
    ],
  },
  {
    path: "global-api-usage",
    loadComponent: () =>
      import("./home/global-api-usage/global-api-usage.component").then((m) => m.GlobalApiUsageComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/apis", text: "API Explorer" },
      { url: "/api-usage", text: "API Usage" },
      { url: "/global-api-usage", text: "Global" },
    ],
  },
  {
    path: "dashboard",
    loadComponent: () => import("./home/home/home.component").then((m) => m.HomeComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
  },
  {
    path: "dashboard/:csp",
    loadComponent: () => import("./home/dashboard-v2/dashboard-v2.component").then((m) => m.DashboardV2Component),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/:csp", text: ":CSP" },
    ],
  },
  {
    path: "statistics",
    loadComponent: () => import("./home/stats/stats.component").then((m) => m.StatsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/statistics", text: "Statistics" },
    ],
  },
  {
    path: "scheduler/:csp",
    loadComponent: () => import("./home/scheduler/scheduler.component").then((m) => m.SchedulerComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/:csp", text: ":CSP" },
    ],
  },
  {
    path: "user",
    loadComponent: () => import("./home/user-info/user-info.component").then((m) => m.UserInfoComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/user", text: "User Info" },
    ],
  },
  {
    path: "endpoints",
    loadComponent: () => import("./home/endpoints/endpoints.component").then((m) => m.EndpointsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/endpoints", text: "Endpoints" },
    ],
  },
  {
    path: "patch-mgmt/gcp",
    loadComponent: () => import("./home/patch-mgmt-gcp/patch-mgmt-gcp.component").then((m) => m.PatchMgmtGcpComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/gcp", text: "GCP" },
      { url: "/patch-mgmt/gcp", text: "Patch Management" },
    ],
  },
  {
    path: "patch-mgmt/gcp/projects/:projectId/regions/:region/vms",
    loadComponent: () =>
      import("./home/patch-mgmt-gcp-vms/patch-mgmt-gcp-vms.component").then((m) => m.PatchMgmtGcpVmsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/gcp", text: "GCP" },
      { url: "/patch-mgmt/gcp", text: "Patch Management" },
      {
        url: "/patch-mgmt/gcp/projects/:projectId/regions/:region/vms",
        text: "VMs",
      },
    ],
  },
  {
    path: "patch-mgmt/gcp/projects/:projectId/deployments",
    loadComponent: () =>
      import("./home/patch-mgmt-gcp-deployments/patch-mgmt-gcp-deployments.component").then(
        (m) => m.PatchMgmtGcpDeploymentsComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/gcp", text: "GCP" },
      { url: "/patch-mgmt/gcp", text: "Patch Management" },
      {
        url: "/patch-mgmt/gcp/projects/:projectId/deployments",
        text: "Deployments",
      },
    ],
  },
  {
    path: "patch-mgmt/aws/regions/:region/deployments/:status",
    loadComponent: () => import("./home/patch-mgmt/patch-mgmt.component").then((m) => m.PatchMgmtComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      {
        url: "/patch-mgmt/aws/regions/:region/deployments/:status",
        text: "Patch Management",
      },
    ],
  },
  {
    path: "patch-mgmt/aws/regions/:region/accounts/:accountId",
    loadComponent: () => import("./home/patch-mgmt-vms/patch-mgmt-vms.component").then((m) => m.PatchMgmtVMsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      {
        url: "/patch-mgmt/aws/regions/:region/deployments/ALL",
        text: "Patch Management",
      },
      {
        url: "/patch-mgmt/aws/regions/:region/accounts/:accountId",
        text: "VMs",
      },
    ],
  },
  {
    path: "approvals",
    redirectTo: "/dashboard#change-approvals",
    pathMatch: "full",
  },
  {
    path: "auto-remediation",
    redirectTo: "auto-remediation/aws",
    pathMatch: "full",
  },
  {
    path: "auto-remediation/:csp",
    loadComponent: () =>
      import("./home/auto-remediation/auto-remediation.component").then((m) => m.AutoRemediationComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/:csp", text: ":CSP" },
      { url: "/auto-remediation/:csp", text: "Auto Remediation" },
    ],
  },
  {
    path: "auto-remediation/:csp/:resource",
    loadComponent: () =>
      import("./home/auto-remediation/auto-remediation.component").then((m) => m.AutoRemediationComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/:csp", text: ":CSP" },
      { url: "/auto-remediation/:csp", text: "Auto Remediation" },
      { url: "auto-remediation/:csp/:resource", text: ":resource" },
    ],
  },
  {
    path: "auto-remediation-rules/:csp/:rule",
    loadComponent: () =>
      import("./home/auto-remediation-rule/auto-remediation-rule.component").then(
        (m) => m.AutoRemediationRuleComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/:csp", text: ":CSP" },
      { url: "/auto-remediation/:csp", text: "Auto Remediation" },
      { url: "/auto-remediation-rules/:csp/:rule", text: ":RULE" },
    ],
  },
  {
    path: "images",
    loadComponent: () =>
      import("./home/images-dashboard/images-dashboard.component").then((m) => m.ImagesDashboardComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/images", text: "Images" },
    ],
  },
  {
    path: "tenancies",
    redirectTo: `tenancies/${DEFAULT_SECTION}`,
    pathMatch: "full",
  },
  {
    path: "tenancies/create",
    loadComponent: () =>
      import("./home/tenancy-creation/tenancy-creation.component").then((m) => m.TenancyCreationComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/tenancies/create", text: "Create Tenancy" },
    ],
  },
  {
    path: "tenancies/delete",
    loadComponent: () =>
      import("./home/tenancy-deletion/tenancy-deletion.component").then((m) => m.TenancyDeletionComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/tenancies/delete", text: "Delete Tenancy" },
    ],
  },
  {
    path: "tenancies/:section",
    loadComponent: () => import("./home/tenancy-mgmt/tenancies-mgmt.component").then((m) => m.TenanciesMgmtComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/tenancies/:section", text: "Tenancy :section" },
    ],
  },
  {
    path: "account-onboarding",
    loadComponent: () =>
      import("./home/account-onboarding/account-onboarding.component").then((m) => m.AccountOnboardingComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/account-onboarding", text: "Account Onboarding" },
    ],
  },
  {
    path: "account-onboarding/new-version/:csp",
    loadComponent: () =>
      import("./home/account-onboarding/new-version/account.component").then((m) => m.AccountComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/:csp", text: ":CSP" },
      { url: "/account-onboarding/new-version/:csp", text: "Account Onboarding" },
    ],
  },
  {
    path: "account-onboarding/old-version",
    loadComponent: () =>
      import("./home/account-onboarding/old-version/account-onboarding.component").then(
        (m) => m.AccountOnboardingComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/account-onboarding/old-version", text: "Account Onboarding" },
    ],
  },
  {
    path: "account-onboarding/old-version/account-linking",
    loadComponent: () =>
      import("./home/account-onboarding/old-version/account-linking/account-linking.component").then(
        (m) => m.AccountLinkingComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/account-onboarding/old-version", text: "Account Onboarding" },
      { url: "/account-onboarding/old-version/account-linking", text: "Account Linking" },
    ],
  },
  {
    path: "account-onboarding/old-version/service-model-selection",
    loadComponent: () =>
      import("./home/account-onboarding/old-version/service-model-selection/service-model-selection.component").then(
        (m) => m.ServiceModelSelectionComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/account-onboarding/old-version", text: "Account Onboarding" },
      { url: "/account-onboarding/old-version/service-model-selection", text: "Service Model Selection" },
    ],
  },
  {
    path: "account-onboarding/old-version/resource-scanning",
    loadComponent: () =>
      import("./home/account-onboarding/old-version/resource-scanning/resource-scanning.component").then(
        (m) => m.ResourceScanningComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/account-onboarding/old-version", text: "Account Onboarding" },
      { url: "/account-onboarding/old-version/resource-scanning", text: "Resource Scanning" },
    ],
  },
  {
    path: "account-onboarding/old-version/security-tool-compliance",
    loadComponent: () =>
      import("./home/account-onboarding/old-version/security-tool-compliance/security-tool-compliance.component").then(
        (m) => m.SecurityToolComplianceComponent
      ),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard/aws", text: "AWS" },
      { url: "/account-onboarding/old-version", text: "Account Onboarding" },
      { url: "/account-onboarding/old-version/security-tool-compliance", text: "Security Tool Compliance" },
    ],
  },
  { path: "apis", loadChildren: () => import("./swagger/swagger.module").then((m) => m.SwaggerModule) },
  {
    path: "incident-management/create-ticket",
    loadComponent: () =>
      import("./remedy-form/create-ticket/create-ticket.component").then((m) => m.CreateTicketComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", fragment: "incident-management", text: "Incident Management" },
    ],
  },
  {
    path: "incident-management/list-tickets",
    loadComponent: () =>
      import("./remedy-form/list-tickets/list-tickets.component").then((m) => m.ListTicketsComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", fragment: "incident-management", text: "Incident Management" },
    ],
  },
  {
    path: "budget-alerts/create-budget",
    loadComponent: () =>
      import("./budget-and-alerts/create-budget/create-budget.component").then((m) => m.CreateBudgetComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", fragment: "budget-alerts", text: "Budget & Alert Service" },
    ],
  },
  {
    path: "budget-alerts/list-budgets",
    loadComponent: () =>
      import("./budget-and-alerts/list-budget/list-budget.component").then((m) => m.ListBudgetComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", fragment: "budget-alerts", text: "Budget & Alert Service" },
    ],
  },
  {
    path: "budget-alerts/edit-budget/:budgetId",
    loadComponent: () =>
      import("./budget-and-alerts/create-budget/create-budget.component").then((m) => m.CreateBudgetComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", fragment: "budget-alerts", text: "Budget & Alert Service" },
    ],
  },
  {
    path: "vcloudsmart/assistant",
    loadComponent: () => import("./home/vcloudsmart/llm/llm.component").then((m) => m.LLMComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", text: "VCloudSmart", fragment: "vcloudsmart" },
    ],
  },
  {
    path: "changelog",
    loadComponent: () => import("./home/changelog/changelog.component").then((m) => m.ChangelogComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [{ url: "/dashboard", text: "Dashboard" }],
  },
  {
    path: "request-access",
    loadComponent: () => import("./request-access/request-access.component").then((m) => m.RequestAccessComponent),
    canActivate: [AuthGuard],
    pathMatch: "full",
    data: [
      { url: "/dashboard", text: "Dashboard" },
      { url: "/dashboard", fragment: "request-access", text: "Request Access" },
    ],
  },
  {
    path: "**",
    loadComponent: () => import("./home/not-found/not-found.component").then((m) => m.NotFoundComponent),
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
