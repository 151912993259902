import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

import { logLevel } from "../models/logging";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  logLevel: logLevel = environment.log_level;

  setLogLevel(level: logLevel) {
    this.logLevel = level;
  }

  private shouldLog(level: logLevel): boolean {
    return level <= this.logLevel;
  }

  private write(level: logLevel, ...msgs: any[]) {
    if (this.shouldLog(level)) {
      console.log(...msgs);
    }
  }

  error(...msgs: any[]) {
    if (this.shouldLog(logLevel.ERROR)) {
      console.error(...msgs);
    }
  }

  warning(...msgs: any[]) {
    if (this.shouldLog(logLevel.WARNING)) {
      console.warn(...msgs);
    }
  }

  info(...msgs: any[]) {
    this.write(logLevel.INFO, ...msgs);
  }

  log(...msgs: any[]) {
    this.info(...msgs);
  }

  debug(...msgs: any[]) {
    this.write(logLevel.DEBUG, ...msgs);
  }
}
