import { BrowserModule, Title } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AuthGuard } from "./shared/guard/guard.module";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule, NgOptimizedImage, registerLocaleData } from "@angular/common";
import { RatingConfig } from "ngx-bootstrap/rating";
import { FooterComponent } from "./common/footer/footer.component";
import { BreadcrumbsComponent } from "./common/breadcrumbs/breadcrumbs.component";
import { HeaderComponent } from "./common/header/header.component";
import { UserFeedbackComponent } from "./common/user-feedback/user-feedback.component";
import { defineLocale } from "ngx-bootstrap/chronos";
import {
  deLocale,
  frLocale,
  esLocale,
  itLocale,
  enGbLocale,
  roLocale,
  trLocale,
  nlLocale,
  hiLocale,
  ptBrLocale,
} from "ngx-bootstrap/locale";

import localeGB from "@angular/common/locales/en-GB";
import localeFR from "@angular/common/locales/fr";
import localeIT from "@angular/common/locales/it";
import localeES from "@angular/common/locales/es";
import localeDE from "@angular/common/locales/de";
import localeENIN from "@angular/common/locales/en-IN";
import localeHI from "@angular/common/locales/hi";
import localeBNIN from "@angular/common/locales/bn-IN";
import localeCL from "@angular/common/locales/es-CL";
import localeTK from "@angular/common/locales/tk";
import localeTR from "@angular/common/locales/tr";
import localeNE from "@angular/common/locales/ne";
import localeRO from "@angular/common/locales/ro";
import { VFIconComponent } from "./shared/component/vficon/vficon.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { LLMComponent } from "./home/vcloudsmart/llm/llm.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ModalModule } from "ngx-bootstrap/modal";

const LOADED_LOCALES = ["en-US", "bn-IN", "de", "en-GB", "en-IN", "es-CL", "fr", "hi", "it", "nl", "ro", "tk", "tr"];
registerLocaleData(localeENIN, "en-IN");
registerLocaleData(localeGB, "en-GB");
registerLocaleData(localeIT, "it");
registerLocaleData(localeFR, "fr");
registerLocaleData(localeDE, "de");
registerLocaleData(localeES, "de");
registerLocaleData(localeHI, "hi");
registerLocaleData(localeBNIN, "bn-IN");
registerLocaleData(localeCL, "es-CL");
registerLocaleData(localeTK, "tk");
registerLocaleData(localeTR, "tr");
registerLocaleData(localeNE, "nl");
registerLocaleData(localeRO, "ro");

defineLocale("de", deLocale);
defineLocale("tr", trLocale);
defineLocale("es", esLocale);
defineLocale("ro", roLocale);
defineLocale("it", itLocale);
defineLocale("fr", frLocale);
defineLocale("nl", nlLocale);
defineLocale("hi", hiLocale);
defineLocale("pt-br", ptBrLocale);
defineLocale("en-gb", enGbLocale);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgOptimizedImage,
    FooterComponent,
    BreadcrumbsComponent,
    HeaderComponent,
    MatSnackBarModule,
    UserFeedbackComponent,
    VFIconComponent,
    TooltipModule,
    LLMComponent,
    ModalModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    Title,
    RatingConfig,
    {
      provide: LOCALE_ID,
      useValue: LOADED_LOCALES.includes(navigator.language) ? navigator.language : "en-GB",
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
