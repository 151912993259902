import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { LoggerService } from "../../services/logger.service";
import { CommonModule } from "@angular/common";
import { VFIconComponent } from "../vficon/vficon.component";
import { FormsModule } from "@angular/forms";
import { PageNavigation, PaginationState } from "../../models/pagination";
import { DEFAULT_PAGE_SIZE } from "../../constant/tables";

@Component({
  standalone: true,
  imports: [CommonModule, VFIconComponent, FormsModule],
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent<T> implements OnChanges, OnInit {
  @Input() tableRows: T[];
  @Input() pageNumber: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_SIZE;
  @Input() validPageSizes: Array<number> = [5, 10, 25, 50, 100];

  @Input() isDynamic = false;
  @Input() estimatedRows: number = -1;
  @Input() exactRows: number | undefined = undefined;

  @Output() pageChanged: EventEmitter<PaginationState<T>> = new EventEmitter();

  public nPages = 1;
  private leftIndex = 0;
  private rightIndex = 0;
  private currentPage: T[] = [];

  constructor(private logger: LoggerService) {}

  ngOnInit() {
    this.pageChanged.subscribe((state) => {
      this.logger.info("pagination state changed to:", state);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.info("pagination params changed to:", changes);
    this.init();
    this.notify();
  }

  public getLastPage(): string | number {
    if (this.exactRows !== undefined) {
      return Math.ceil(this.exactRows / this.pageSize);
    }
    if (this.isDynamic && (this.tableRows || []).length > this.pageSize) {
      if (this.estimatedRows > 0) {
        return Math.ceil(this.estimatedRows / this.pageSize);
      } else {
        return "many";
      }
    }
    return this.nPages;
  }

  public get state(): PaginationState<T> {
    return {
      leftIndex: this.leftIndex,
      rightIndex: this.rightIndex,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      nPages: this.nPages,
      currentPage: this.currentPage,
    };
  }

  private init(): void {
    this.nPages = Math.max(1, Math.ceil(this.tableRows.length / this.pageSize));
    this.leftIndex = this.pageNumber * this.pageSize;
    this.rightIndex = (this.pageNumber + 1) * this.pageSize;
    this.currentPage = this.tableRows.slice(this.leftIndex, this.rightIndex);
  }

  public nextPage(): void {
    if (this.pageNumber !== this.nPages - 1) {
      this.changeToPage(this.pageNumber + 1);
    }
  }

  public previousPage(): void {
    if (this.pageNumber !== 0) {
      this.changeToPage(this.pageNumber - 1);
    }
  }

  public handleBtnClick(event: MouseEvent): void {
    this.changeToPage(parseInt((<HTMLElement>event.target).innerHTML) - 1);
  }

  public handleChangePageSize(newPageSize: string): void {
    const pageSize = parseInt(newPageSize);
    if (this.pageSize !== pageSize) {
      const page = pageSize < this.pageSize ? this.pageNumber : 0;
      const leftIndex = page * this.pageSize;
      const rightIndex = (page + 1) * this.pageSize;
      const currentPage = this.tableRows.slice(leftIndex, rightIndex);
      this.notify({ pageSize, pageNumber: page, leftIndex, rightIndex, currentPage });
    }
  }

  public changeToPage(page: number): void {
    const leftIndex = page * this.pageSize;
    const rightIndex = (page + 1) * this.pageSize;
    const currentPage = this.tableRows.slice(leftIndex, rightIndex);
    this.notify({ pageNumber: page, leftIndex, rightIndex, currentPage });
  }

  public notify(params: Partial<PaginationState<T>> = {}): void {
    this.pageChanged.emit({ ...this.state, ...params });
  }

  public pageNavigation(navNumber: number): PageNavigation {
    const pageNav: PageNavigation = { active: false, pageNo: 1 };
    switch (navNumber) {
      case 1:
        pageNav.active = this.pageNumber === 0;
        pageNav.pageNo =
          this.nPages < 5 || this.pageNumber < 3
            ? 1
            : this.nPages - this.pageNumber < 3
            ? this.nPages - 4
            : this.pageNumber - 1;
        break;
      case 2:
        pageNav.active = this.pageNumber === 1;
        pageNav.pageNo =
          this.nPages < 5 || this.pageNumber < 3
            ? 2
            : this.nPages - this.pageNumber < 3
            ? this.nPages - 3
            : this.pageNumber;
        break;
      case 3:
        pageNav.active =
          this.pageNumber === 2 || (this.pageNumber > 2 && this.nPages >= 5 && this.nPages - this.pageNumber > 2);
        pageNav.pageNo =
          this.nPages < 5 || this.pageNumber < 3
            ? 3
            : this.nPages - this.pageNumber < 3
            ? this.nPages - 2
            : this.pageNumber + 1;
        break;
      case 4:
        pageNav.active =
          (this.nPages < 5 && this.pageNumber === 3) || (this.nPages >= 5 && this.nPages - this.pageNumber === 2);
        pageNav.pageNo =
          this.nPages < 5 || this.pageNumber < 3
            ? 4
            : this.nPages - this.pageNumber < 3
            ? this.nPages - 1
            : this.pageNumber + 2;
        break;
      case 5:
        pageNav.active =
          (this.nPages < 5 && this.pageNumber === 4) || (this.nPages >= 5 && this.nPages - this.pageNumber === 1);
        pageNav.pageNo =
          this.nPages < 5 || this.pageNumber < 3
            ? 5
            : this.nPages - this.pageNumber < 3
            ? this.nPages
            : this.pageNumber + 3;
        break;
      default:
        pageNav.active = false;
        pageNav.pageNo = 1;
        break;
    }
    return pageNav;
  }
}
