import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public alphaRegExp = /[a-zA-Z]/g;
  public dashRegExp = /^\-/;
  public accountIdRegExp = /^'?"?[0-9]{12}'?"?$/;

  constructor(private auth: AuthenticationService) {}

  public rollingMean(numbers: number[]): number[] {
    const means = [];
    for (let i = 1; i <= numbers.length; i++) {
      const slice = numbers.slice(0, i);
      means.push(this.mean(slice));
    }
    return means;
  }

  public linearRegression(yValues: number[]): number[] {
    const n = yValues.length;
    if (n === 0) return [];
    let sumY = 0;
    let sumX = 0;
    let sumX2 = 0;
    let sumXY = 0;
    for (let i = 0; i < n; i++) {
      const x = i;
      const y = yValues[i];
      sumX += x;
      sumY += y;
      sumX2 += x * x;
      sumXY += x * y;
    }
    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);
    const intercept = (sumY - slope * sumX) / n;
    return yValues.map((_, i) => intercept + slope * i);
  }

  public mean(numbers: number[]): number {
    return numbers.reduce((a, b) => a + b, 0) / numbers.length;
  }

  public download(data: string, fileName: string, mime = "text/csv"): void {
    let csp: string;
    try {
      csp = location.pathname.split("/")[3].toUpperCase();
    } catch (e) {
      try {
        csp = location.pathname.split("/").slice(-1)[0].toUpperCase();
      } catch (e2) {
        try {
          csp = location.pathname
            .split("/")
            .find((w) => w.length === 3)
            .toUpperCase();
        } catch (e3) {}
      }
    }

    if (!csp) {
      csp = "Portal";
    }

    // inject csp if not supplied
    if (
      !fileName.startsWith(csp) &&
      !fileName.startsWith(csp.toLowerCase()) &&
      !fileName.startsWith(csp.toUpperCase())
    ) {
      try {
        return this.download(data, `${csp}-${fileName.replace(new RegExp(`-${csp}-`, "i"), "")}`, mime);
      } catch (e) {}
    }

    // inject date if not supplied
    if (!fileName.match(/\d{4}[-]\d{2}[-]\d{2}/)) {
      try {
        const ext = fileName.split(".").slice(-1)[0];
        const stem = fileName.split(".")[0];
        return this.download(data, `${stem}-${new Date().toISOString().split(".")[0]}.${ext}`, mime);
      } catch (e) {}
    }

    // inject report title
    try {
      const reportWords = location.pathname
        .split("/")
        .map((t) => decodeURIComponent(t).split(" "))
        .flat()
        .filter((w) => w && w.length > 3)
        .filter((w) => w.toLowerCase() !== csp.toLowerCase());
      if (!reportWords.every((w) => fileName.toLowerCase().includes(w.toLowerCase()))) {
        return this.download(
          data,
          `${csp}-${reportWords.map((w) => w.substring(0, 1).toUpperCase() + w.substring(1)).join("-")}-${fileName}`,
          mime
        );
      }
    } catch (e) {}

    let parseCSV = data;
    try {
      parseCSV = data
        .split("\n")
        .map((row) =>
          row
            .split(",")
            .map((col) =>
              this.alphaRegExp.test(col)
                ? col.replace(this.dashRegExp, " -")
                : this.accountIdRegExp.test(col.trim())
                ? `${col}\t`
                : col
            )
            .join(",")
        )
        .join("\n");
    } catch (e) {}

    if (this.auth.tenant !== "vodafone") {
      Swal.fire({
        title: "Not Allowed",
        text: "This feature is not allowed for your tenant.",
        icon: "warning",
        allowOutsideClick: true,
      });
    } else {
      const pom = document.createElement("a");
      const blob = new Blob([parseCSV], { type: `${mime};charset=utf-8;` });
      pom.href = URL.createObjectURL(blob);
      pom.setAttribute(
        "download",
        fileName
          .split(" ")
          .filter(Boolean)
          .map((w) => w.split("-"))
          .flat()
          .filter(Boolean)
          .map((w, idx) => (w === csp || (idx === 0 && w.length === 3) ? w.toUpperCase() : w))
          .join("-")
          .replace(/\.\d+Z/i, "")
      );
      pom.click();
    }
  }
}
