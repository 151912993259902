export const MAX_API_RESPONSE_LIMIT = 5000;
export const OPERATOR_SCORES = {
  "=": 1,
  IS: 1,
  "IS NOT": 2,
  "!=": 2,
  "<>": 2,
  ">=": 3,
  ">": 3,
  "<=": 4,
  "<": 4,
  LIKE: 10,
  "NOT LIKE": 11,
};
export const REGEX_VCS_PAGE_URL =
  /^\/vcloudsmart\/(?<version>v\d+)\/(?<csp>[^/]+)\/(?<section>[^/]+)\/(?<page>[^/]+)(\/(?<tab>[^/]+))?.*$/;
