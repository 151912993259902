import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, of, Subscription, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import { Attribute, CSP } from "../models/csp-api";
import { catchError, delay, map, mergeMap, tap } from "rxjs/operators";
import { LoggerService } from "./logger.service";
import { EventsService } from "./events.service";
import { SweetAlertResult } from "sweetalert2";
import { AlertsService } from "./alerts.service";
import { LookerStudioService } from "./looker-studio.service";
import { ServiceCloudProvider } from "../models/services";
import { TenancyPage, UserDetails, UserPermissions } from "../models/tenancies";
import { GcpOperationsData } from "../models/gcp-api";
import { QUOTA_HIST_ITEM_LEN } from "../constant/llm";

@Injectable({
  providedIn: "root",
})
export class PcsApiService {
  public readonly baseUrl: string;
  private cachable: Array<RegExp> = [
    /^tenancies-v1\/schemas\/[^/]+\/?(\?.*)?$/,
    /^tenancies-v1\/metadata\/cloud-providers\/[^/]+\/services\/[^/]+\/statistics\/?(\?.*)?$/,
    /^tenancies-v1\/metadata\/services\/[^/]+\/metrics\/[^/]+\/?(\?.*)?$/,
    /^tenancies-v1\/metadata\/services\/?(\?.*)?$/,
    /^tenancies-v1\/metadata\/cloud-providers\/[^/]+\/api-usage\/?(\?.*)?$/,
    /^tenancies-v1\/metadata\/cloud-providers\/[^/]+\/api\/[^/]+\/?(\?.*)?$/,
    /^tenancies-v1\/[^/]+\/apis\/users\/?(\?.*)?$/,
    /^(aws|gcp|azure|oci|drcc)-v[12]\/(accounts|subscriptions|projects|compartments)\/?\?Attributes=VerifiedTimestamp&Paginate=false$/,
    /^aws-v2\/hardened-images\/[^/]+\/?(\?.*)?$/,
    /^aws-v2\/endpoints\/(consumer|provider)\/?(\?.*)?$/,
    /^[^/]\/auto-remediation\/rules\/?(\?.*)?$/,
    /^aws-v2\/patch-mgmt\/regions\/[^/]+\/?(\?.*)?$/,
    /^(aws|gcp|oci|drcc|azure)-v[12]\/?(\?.*)?$/,
    /^[^/]+\/metadata\/specifications\/api\/?(\?.*)?$/,
  ];

  constructor(
    private http: HttpClient,
    private alerts: AlertsService,
    private looker: LookerStudioService,
    private logger: LoggerService,
    private auth: AuthenticationService,
    private eventsService: EventsService
  ) {
    this.baseUrl = environment.urlPcsApi;
  }

  public daysSince(date: string): number {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / (24 * 3600 * 1000));
  }

  public JSONStringifyStable(obj: any): string {
    const allKeys = new Set<string>();
    JSON.stringify(obj, (key, value) => (allKeys.add(key), value));
    return JSON.stringify(obj, Array.from(allKeys).sort());
  }

  public set capturedResponses(v: { [url: string]: Array<{ body: { Url: string; Params?: any }; results: any }> }) {
    sessionStorage.setItem("capturedResponsesPcsApi", JSON.stringify(v));
  }

  public get capturedResponses(): {
    [url: string]: Array<{ body: { Url: string; Params?: any }; results: any }>;
  } {
    const maybe = sessionStorage.getItem("capturedResponsesPcsApi");
    if (maybe) {
      const v = JSON.parse(maybe);
      if (v) {
        return v;
      }
    }
    return { [location.pathname]: [] };
  }

  public captureResponse(results: any, body: { Url: string; Params?: any }): void {
    const url = body?.Url ?? "";
    if (results && !url.includes("/users/me") && !url.includes("/schemas/")) {
      const stringifiedBody = this.JSONStringifyStable(body);
      const cr = this.capturedResponses;
      const key = location.pathname;
      cr[key] = (cr[key] ?? []).filter((f) => this.JSONStringifyStable(f.body) !== stringifiedBody).slice(-15);
      const resultsStringified = JSON.stringify(results);
      cr[key].push({
        body,
        results:
          resultsStringified.length > QUOTA_HIST_ITEM_LEN
            ? `${resultsStringified.slice(0, QUOTA_HIST_ITEM_LEN)}... (WARNING: ${
                resultsStringified.length - QUOTA_HIST_ITEM_LEN
              } characters of response have been truncated)`
            : resultsStringified,
      });
      this.capturedResponses = cr;
    }
  }

  public notifyRelease(): Subscription {
    return this.get<{ Html: string }>("tenancies-v1/metadata/services/PCS-1013/changelog")
      .pipe(map((response) => response?.Html ?? ""))
      .subscribe((text) => {
        const template = document.createElement("template");
        const regex = /[^0-9]*([-_.0-9]+)[^0-9]*(\d{4})[^0-9]+(\d{2})[^0-9]+(\d+)/;
        template.innerHTML = text;
        const headings = [];
        template.content.querySelectorAll("h2").forEach((el) => headings.push(el));
        const second = 1000;
        for (const h of headings) {
          const m = regex.exec(h.innerText);
          if (m) {
            const release = `${m[2]}-${m[3]}-${m[4]}`;
            const cacheKey = `release-seen-${release}`;
            if (this.daysSince(release) <= 7 && !localStorage.getItem(cacheKey)) {
              this.alerts.addAlert({
                text: `<p>We released new changes on ${this.looker.fmtDay(
                  release
                )}. Please navigate to <a target="_blank" href="/changelog">release notes</a> to see the details.</p>`,
                type: "info",
                dismissOnTimeout: 30 * second,
                dismissible: true,
              });
              localStorage.setItem(cacheKey, "1");
              return;
            }
          }
        }
      });
  }

  private getCacheKey(url: string): string {
    return `cache-${url}`;
  }

  public invalidateCache(url: string): void {
    const prefix = new URL(`https://example.com/${url}`).pathname.slice(1);
    Object.keys(sessionStorage)
      .filter((key) => key.startsWith(this.getCacheKey(prefix)))
      .forEach((key) => {
        this.logger.info({ msg: "removing cache", key, url, prefix });
        sessionStorage.removeItem(key);
      });
  }

  public getInventoryTableDefinition<T>(
    resource: "AWS_Account" | "GCP_Project" | "Azure_Subscription" | "OCI_Compartment" | "DRCC_Compartment"
  ): Observable<T> {
    return this.get<T>(`tenancies-v1/schemas/${resource}`);
  }

  private getHeaders(): HttpHeaders {
    const headers = {
      Authorization: this.auth.getIdToken(),
      Accept: "application/json, */*",
    };
    if (this.auth.tenant && this.auth.tenant !== "undefined" && this.auth.tenant !== "null") {
      headers["x-vf-api-tenant"] = this.auth.tenant;
    }
    return new HttpHeaders(headers);
  }

  private request<T>(
    method: string,
    url: string,
    options: Partial<{
      headers: HttpHeaders;
      body: any;
      params: HttpParams;
    }> = {}
  ): Observable<T> {
    let headers = new HttpHeaders();

    try {
      headers = this.getHeaders();
    } catch (e) {
      if (e.message === "Session Expired") {
        this.auth.autoLogout();
      }
      return throwError(e);
    }

    if (options.headers) {
      for (const k of options.headers.keys()) {
        headers.set(k, options.headers.get(k));
      }
    }

    let tries = 0;
    const maxTries = environment.production ? 1 : 0;
    const base = 1;
    const cap = 6;
    const sec = 1000;

    const request = this.http.request<T>(method, `${this.baseUrl}/${url}`, {
      body: options.body,
      headers,
      observe: "body",
      responseType: "json",
      params: options.params,
    });

    const observable = request.pipe(
      catchError((e) => {
        if (tries >= maxTries) {
          // centralised logging of all API errors
          this.logger.error(`Error while requesting ${method} ${url} (tried ${tries}/${maxTries} times)`, options, e);
          return throwError(e);
        } else {
          return of(null).pipe(
            tap(() => tries++),
            delay(Math.ceil(sec * (Math.random() * Math.min(cap, base * 2 ** tries)))),
            mergeMap(() => observable)
          );
        }
      })
    );

    return observable;
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.request<T>("post", url, { body });
  }

  public getAllPages<T>(url: string, paginationToken?: string): Observable<{ Resources: Array<T> }> {
    const prefix = "https://example.com/";
    const parsedUrl = new URL(`${prefix}${url}`);

    if (
      ["/gcp-v2/projects", "/aws-v2/accounts", "/oci-v2/compartments", "/drcc-v1/compartments"].some(
        (s) => parsedUrl.pathname === s
      ) &&
      parsedUrl.searchParams.get("Paginate") !== "true"
    ) {
      parsedUrl.searchParams.set("Paginate", "true");
    }

    if (paginationToken) {
      this.logger.info("paginating ", url, " token: ", paginationToken);
      if (parsedUrl.searchParams.has("PaginationToken")) {
        parsedUrl.searchParams.delete("PaginationToken");
      }
      parsedUrl.searchParams.set("PaginationToken", paginationToken);
    }

    const urlWithParam = decodeURIComponent(parsedUrl.toString().replace(prefix, ""));

    return this.get<{ Resources: Array<T>; PaginationToken?: string }>(urlWithParam).pipe(
      mergeMap((response) => {
        const data = response.Resources || [];
        if (response.PaginationToken) {
          return this.getAllPages<T>(url, response.PaginationToken).pipe(
            map((nextResponse) => ({
              Resources: data.concat(nextResponse.Resources || []),
            }))
          );
        } else {
          return of(response);
        }
      })
    );
  }

  private getLocalStorageSize(storage = localStorage): number {
    let totalSize = 0;
    for (let key in storage) {
      if (storage.hasOwnProperty(key)) {
        totalSize += (storage[key].length + key.length) * 2;
      }
    }
    return totalSize;
  }

  public howMuchStorageLeft(storage = localStorage): {
    bytesLeft: number;
    percent: number;
    limit: number;
    used: number;
  } {
    const limit = 5 * 1024 * 1024; // 5 MB
    const used = this.getLocalStorageSize(storage);
    return { bytesLeft: limit - used, percent: used / limit, limit, used };
  }

  public get<T>(url: string, params?: HttpParams): Observable<T> {
    const cacheKey = this.getCacheKey(url);
    const maybeCache = sessionStorage.getItem(cacheKey);
    if (!params && maybeCache) {
      const response = JSON.parse(maybeCache);
      this.logger.info({ msg: "cache hit", cacheKey, url, response });
      this.captureResponse(response, { Url: url, Params: params });
      return of(response);
    }
    return this.request<T>("get", url, { params }).pipe(
      tap((response) => {
        if (params) {
          return;
        }
        const matchingRegex = this.cachable.find((r) => r.test(url));
        if (!matchingRegex) {
          return;
        }
        const dataStringified = JSON.stringify(response);
        const storageStats = this.howMuchStorageLeft(sessionStorage);
        this.logger.info({ storageStats, dataSize: dataStringified.length * 2 });
        if ((dataStringified.length * 2) / storageStats.limit > 0.02) {
          return;
        }
        if (dataStringified.length * 2 >= storageStats.bytesLeft || storageStats.percent >= 0.8) {
          this.logger.warning("revoking response cache");
          for (const key of Object.keys(sessionStorage).filter((k) => k.startsWith("cache-"))) {
            sessionStorage.removeItem(key);
          }
        }
        this.logger.info({
          msg: "caching",
          url,
          matchingRegex,
          params,
          data: dataStringified,
          dataBytes: dataStringified.length * 2,
          dataChars: dataStringified.length,
        });
        sessionStorage.setItem(cacheKey, dataStringified);
      }),
      tap((response) => this.captureResponse(response, { Url: url, Params: params }))
    );
  }

  public patch<T>(url: string, body: any): Observable<T> {
    return this.request<T>("patch", url, { body }).pipe(
      tap(() => {
        this.invalidateCache(url);
      })
    );
  }

  public put<T>(url: string, body: any): Observable<T> {
    return this.request<T>("put", url, { body }).pipe(
      tap(() => {
        this.invalidateCache(url);
      })
    );
  }

  public delete<T>(url: string, body: any = null): Observable<T> {
    let options;
    if (body === null) {
      options = {};
    } else {
      options = { body };
    }
    return this.request<T>("delete", url, options).pipe(
      tap(() => {
        this.invalidateCache(url);
      })
    );
  }

  private getInventoryTableSpec(schema: { type: "object"; properties: { [p: string]: any } }): Array<Attribute> {
    return Object.entries(schema["properties"])
      .sort(([_, a], [__, b]) => ((a.order ?? 99) >= (b.order ?? 99) ? 1 : -1))
      .map(([property, propertySchema]) => {
        return {
          name: property,
          default: propertySchema["default"] ?? "unknown",
          format: propertySchema["format"],
          type: propertySchema["type"],
          modifiable: propertySchema["isModifiable"] ?? true,
          options: propertySchema["enum"],
          pattern: propertySchema["pattern"],
          displayName: propertySchema["displayName"],
          readonly: !!propertySchema["isReadOnly"] ?? false,
          helpText: propertySchema["description"],
          filterable: propertySchema["isFilterable"] ?? true,
        };
      });
  }

  private getAttributesAws(): Observable<Array<Attribute>> {
    return this.getInventoryTableDefinition<any>("AWS_Account").pipe(
      map((schema) => {
        const spec = this.getInventoryTableSpec(schema);
        return spec
          .slice(0, 2)
          .concat([
            {
              name: "CostConsumption",
              modifiable: false,
              helpText: `The AWS unblended cost for the account (YTD) in ${
                this.eventsService?.currency?.value?.text ?? "USD"
              }. See more info in VCloudSmart.`,
              readonly: true,
              type: "string",
              displayName: "Cost (YTD)",
              default: 0,
              filterable: false,
            } as Attribute,
            {
              name: "AssetCount",
              modifiable: false,
              helpText: "The number of all resources in the account. See more info in VCloudSmart.",
              readonly: true,
              type: "string",
              displayName: "Asset Count",
              default: 0,
              filterable: false,
            } as Attribute,
          ])
          .concat(spec.slice(2));
      })
    );
  }

  private getAttributesGcp(): Observable<Array<Attribute>> {
    return this.getInventoryTableDefinition<any>("GCP_Project").pipe(
      map((schema) => {
        const spec = this.getInventoryTableSpec(schema);
        return spec
          .slice(0, 2)
          .concat([
            {
              name: "CostConsumption",
              modifiable: false,
              helpText: `The GCP usage cost for the project (YTD) in ${
                this.eventsService?.currency?.value?.text ?? "USD"
              }. See more info in VCloudSmart.`,
              readonly: true,
              type: "string",
              displayName: "Cost (YTD)",
              default: 0,
              filterable: false,
            } as Attribute,
            {
              name: "AssetCount",
              modifiable: false,
              helpText: "The number of all resources in the project. See more info in VCloudSmart.",
              readonly: true,
              type: "string",
              displayName: "Asset Count",
              default: 0,
              filterable: false,
            } as Attribute,
          ])
          .concat(spec.slice(2));
      }),
      tap((attrs: Attribute[]) => {
        try {
          attrs.find(({ name }) => name === "ProjectId").linkFn = (projectId: string) =>
            `https://console.cloud.google.com/welcome?project=${projectId}`;
        } catch (e) {
          this.logger.error(e);
        }
      })
    );
  }

  private getAttributesAzure(): Observable<Array<Attribute>> {
    return this.getInventoryTableDefinition<any>("Azure_Subscription").pipe(
      map((schema) => {
        const spec = this.getInventoryTableSpec(schema);
        return spec
          .slice(0, 2)
          .concat([
            {
              name: "CostConsumption",
              modifiable: false,
              helpText: `The Azure usage cost for the subscription (YTD) in ${
                this.eventsService?.azureCurrency?.value?.text ?? "USD"
              }. See more info in VCloudSmart.`,
              readonly: true,
              type: "string",
              displayName: "Cost (YTD)",
              default: 0,
              filterable: false,
            } as Attribute,
            {
              name: "AssetCount",
              modifiable: false,
              helpText: "The number of all resources in the subscription. See more info in VCloudSmart.",
              readonly: true,
              type: "string",
              displayName: "Asset Count",
              default: 0,
              filterable: false,
            } as Attribute,
          ])
          .concat(spec.slice(2));
      })
    );
  }

  private getAttributesOci(): Observable<Array<Attribute>> {
    return this.getInventoryTableDefinition<any>("OCI_Compartment").pipe(
      map((schema) => {
        const spec = this.getInventoryTableSpec(schema);
        return spec
          .slice(0, 2)
          .concat([
            {
              name: "CostConsumption",
              modifiable: false,
              helpText: `The OCI usage cost for the compartment (YTD) in ${
                this.eventsService?.currency?.value?.text ?? "USD"
              }. See more info in VCloudSmart.`,
              readonly: true,
              type: "string",
              displayName: "Cost (YTD)",
              default: 0,
              filterable: false,
            } as Attribute,
            {
              name: "AssetCount",
              modifiable: false,
              helpText: "The number of all resources in the compartment. See more info in VCloudSmart.",
              readonly: true,
              type: "string",
              displayName: "Asset Count",
              default: 0,
              filterable: false,
            } as Attribute,
          ])
          .concat(spec.slice(2));
      })
    );
  }

  private getAttributesDrcc(): Observable<Array<Attribute>> {
    return this.getInventoryTableDefinition<any>("DRCC_Compartment").pipe(
      map((schema) => {
        const spec = this.getInventoryTableSpec(schema);
        return spec
          .slice(0, 2)
          .concat([
            {
              name: "CostConsumption",
              modifiable: false,
              helpText: `The spend for the compartment (YTD) in ${
                this.eventsService?.drccCurrency?.value?.text ?? "USD"
              }. See more info in VCloudSmart.`,
              readonly: true,
              type: "string",
              displayName: "Cost (YTD)",
              default: 0,
              filterable: false,
            } as Attribute,
            {
              name: "AssetCount",
              modifiable: false,
              helpText: "The number of all resources in the compartment. See more info in VCloudSmart.",
              readonly: true,
              type: "string",
              displayName: "Asset Count",
              default: 0,
              filterable: false,
            } as Attribute,
          ])
          .concat(spec.slice(2));
      })
    );
  }

  public getAttributes(csp: CSP): Observable<Array<Attribute>> {
    return (
      {
        aws: this.getAttributesAws(),
        gcp: this.getAttributesGcp(),
        azure: this.getAttributesAzure(),
        oci: this.getAttributesOci(),
        drcc: this.getAttributesDrcc(),
      }[csp] || of([])
    ).pipe(
      catchError((e) => {
        this.logger.error(e);
        return of([]);
      })
    );
  }

  public addMissingValuesToResource<T>(attributes: Array<Attribute>, data: Array<T>): Array<T> {
    return data.map((row) => {
      for (const a of attributes) {
        const name = a.name;
        const d = a.default;
        if (d !== undefined && (!row[name] || !row[name].toString().trim())) {
          this.logger.debug("missing ", name, " adding default of ", d, " in ", row);
          row[name] = d;
        }
      }
      return row;
    });
  }

  public displayErrorToUser(e, action: string, customMessage?: string): Promise<SweetAlertResult<Awaited<unknown>>> {
    return this.eventsService.displayErrorToUser(e, action, customMessage);
  }

  public getGcpOperationsData(): Observable<{
    [key: string]: GcpOperationsData;
  }> {
    return this.getAllPages<{ [key: string]: any }>(
      `gcp-v2/projects?Attributes=${[
        "ProjectId",
        "ProjectNumber",
        "Labels.environment",
        "Labels.service_class",
        "Labels.service_level",
        "Labels.managed_by",
        "Labels.local_market",
        "Labels.business_service",
        "Labels.business_service2",
        "Labels.business_service3",
        "Labels.business_service4",
        "Labels.business_service5",
        "Labels.pcs_auto_remediation",
      ]
        .sort()
        .join(",")}`
    ).pipe(
      catchError((e) => {
        this.displayErrorToUser(e, "Get Google Cloud Projects");
        return of({ Resources: [] });
      }),
      map((gcpProjects) => {
        const projects = {};
        for (const p of gcpProjects.Resources.sort((a, b) => (a.ProjectId >= b.ProjectId ? 1 : -1))) {
          projects[p.ProjectId] = {
            Environment: p.environment || "na",
            ServiceClass: p.service_class || "na",
            ServiceLevel: p.service_level || "na",
            ManagedBy: p.managed_by || "na",
            LM: p.local_market || "na",
            BusinessService: p.business_service || "na",
            BusinessService2: p.business_service2 || "na",
            BusinessService3: p.business_service3 || "na",
            BusinessService4: p.business_service4 || "na",
            BusinessService5: p.business_service5 || "na",
            PcsAutoRemediation: p.pcs_auto_remediation || "0",
          };
        }
        return projects;
      })
    );
  }

  public uuidv4(): string {
    let d: number = new Date().getTime(); //Timestamp
    let d2: number = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r: number = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public getServiceCloudProviders(user: UserPermissions): Array<ServiceCloudProvider> {
    const currentTenant = user.user.current_tenant ?? localStorage.getItem("tenant") ?? "vodafone";
    return [
      {
        id: "aws",
        title: "Amazon Web Services",
        icon: "assets/icons-home/aws.svg",
        pages: [
          {
            title: "Cloud Inventory (Account Management)",
            iconPack: "source-mid-render-light-icons",
            iconName: "book",
            url: "/dashboard/aws",
            disabled: !user.permissions.canReadAwsAnyAccount,
            tooltip: user.permissions.canReadAwsAnyAccount
              ? "View, update & validate your AWS accounts"
              : "You need /cloud-providers/aws/accounts or /cloud-providers/aws/accounts/{AccountId} Read permissions",
          },
          {
            title: "Hardened Images",
            phase: "BETA",
            iconName: "dashboard",
            iconPack: "source-mid-render-light-icons",
            tooltip: "View all PCS hardened images in AWS including image names, image IDs, latest release date etc.",
            invisible: currentTenant !== "vodafone",
            url: "/images",
          },
          {
            title: "Scheduler (Auto VM Shutdown)",
            iconName: "clock",
            iconPack: "source-mid-render-light-icons",
            phase: "BETA",
            url: "/scheduler/aws",
            invisible: currentTenant !== "vodafone",
            disabled: !user.permissions.canReadAwsAnyAccount,
            tooltip: "Schedule automatic shutdown of resources overnight",
          },
          {
            title: "Endpoints (Hybrid Connectivity)",
            phase: "ALPHA",
            url: "/endpoints",
            iconName: "gravity",
            iconPack: "source-mid-render-light-icons",
            invisible: currentTenant !== "vodafone",
            queryParams: {
              Region: "eu-central-1",
              Type: "consumer",
            },
            disabled: !user.permissions.isPcs,
            tooltip: "View hybrid connectivity information (VPC endpoints and endpoint services) in AWS",
          },
          {
            title: "Patch Management",
            url: "/patch-mgmt/aws/regions/eu-west-1/deployments/ALL",
            invisible: currentTenant !== "vodafone",
            iconName: "e-sim",
            iconPack: "source-mid-render-light-icons",
            disabled: !user.permissions.canAccessPatchMgmtAws,
            tooltip: user.permissions.canAccessPatchMgmtAws
              ? "Enable patch management in your AWS account(s) and EC2 instance(s)"
              : "You need (/cloud-providers/aws/accounts AND /cloud-providers/aws/patch-mgmt) OR /cloud-providers/aws/accounts/{AccountId} Read permissions",
          },
          {
            title: "API Explorer",
            url: "/apis/aws-v2",
            iconName: "community",
            iconPack: "source-mid-render-light-icons",
            disabled: currentTenant !== "vodafone",
            tooltip: "View & try out the PCS AWS API & download the API spec",
          },
          {
            title: "Statistics",
            iconName: "chart-line",
            iconPack: "source-mid-render-light-icons",
            url: "/statistics",
            queryParams: {
              csp: "aws",
              property: "Environment",
              chartType: "PieChart",
            },
            disabled: currentTenant !== "vodafone" || !user.permissions.canReadAwsAnyAccount,
            tooltip: "Statistics & charts related to your Account",
          },
          {
            title: "Auto-Remediation",
            iconName: "diagnostics",
            iconPack: "source-mid-render-light-icons",
            url: "/auto-remediation/aws",
            phase: "BETA",
            invisible: currentTenant !== "vodafone",
            disabled: !user.permissions.canReadAwsAnyAccount,
            tooltip: user.permissions.canReadAwsAnyAccount
              ? "Enable auto-remediation in your account"
              : "You need /cloud-providers/aws/accounts OR /cloud-providers/aws/accounts/{AccountId} Read permissions",
          },
          {
            title: "Account Onboarding",
            phase: "BETA",
            iconName: "deals",
            iconPack: "source-mid-render-light-icons",
            tooltip: user.permissions.canReadAwsAnyAccount
              ? "AWS Account Onboarding"
              : "You need /cloud-providers/aws/accounts or /cloud-providers/aws/accounts/{AccountId} Read permissions",
            invisible: !user.user.tenancies.includes("pcs"),
            url: "/account-onboarding",
          },
          {
            title: "SOX Compliance Reporting",
            phase: "BETA",
            iconName: "padlock-close",
            iconPack: "source-mid-render-light-icons",
            invisible: !user.user.tenancies.includes("pcs"),
            url: "/sox/aws",
          },
        ],
      },
      {
        id: "gcp",
        title: "Google Cloud Platform",
        icon: "assets/icons-home/gcp.svg",
        pages: [
          {
            title: "Cloud Inventory (Project Management)",
            iconName: "book",
            iconPack: "source-mid-render-light-icons",
            tooltip: user.permissions.canReadGcpAnyProject
              ? "View, update & validate your GCP projects"
              : "You need /cloud-providers/gcp/projects or /cloud-providers/gcp/projects/{projectId} Read permissions",
            disabled: !user.permissions.canReadGcpAnyProject,
            url: "/dashboard/gcp",
          },
          {
            title: "API Explorer",
            iconName: "community",
            iconPack: "source-mid-render-light-icons",
            tooltip: "View & try out the PCS GCP API & download the API spec",
            disabled: currentTenant !== "vodafone",
            url: "/apis/gcp-v2",
          },
          {
            title: "Scheduler (Auto VM Shutdown)",
            tooltip: "Schedule automatic shutdown of resources overnight",
            iconName: "clock",
            iconPack: "source-mid-render-light-icons",
            invisible: currentTenant !== "vodafone",
            disabled: !user.permissions.canReadGcpAnyProject,
            url: "/scheduler/gcp",
            phase: "BETA",
          },
          {
            title: "Statistics",
            tooltip: "Statistics & charts related to your projects",
            iconName: "chart-line",
            iconPack: "source-mid-render-light-icons",
            disabled: currentTenant !== "vodafone" || !user.permissions.canReadGcpAnyProject,
            url: "/statistics",
            queryParams: {
              csp: "gcp",
              property: "environment",
              chartType: "PieChart",
            },
          },
          {
            title: "Patch Management",
            iconName: "e-sim",
            iconPack: "source-mid-render-light-icons",
            tooltip: user.permissions.canAccessPatchMgmtGcp
              ? "Enable patch management in your GCP projects(s) and Compute Engine instance(s)"
              : "You need (/cloud-providers/gcp/projects AND /cloud-providers/gcp/patch-mgmt) OR /cloud-providers/gcp/projects/{ProjectId} Read permissions",
            disabled: !user.permissions.canAccessPatchMgmtGcp,
            invisible: currentTenant !== "vodafone",
            phase: "BETA",
            url: "/patch-mgmt/gcp",
          },
          {
            title: "Auto-Remediation",
            iconName: "diagnostics",
            iconPack: "source-mid-render-light-icons",
            tooltip: user.permissions.canReadGcpAnyProject
              ? "Enable auto-remediation in your projects"
              : "You need /cloud-providers/gcp/projects OR /cloud-projects/gcp/projects/{ProjectId} Read permissions",
            invisible: currentTenant !== "vodafone",
            disabled: !user.permissions.canReadGcpAnyProject,
            phase: "BETA",
            url: "/auto-remediation/gcp",
          },
          {
            title: "SOX Compliance Reporting",
            phase: "BETA",
            iconName: "padlock-close",
            iconPack: "source-mid-render-light-icons",
            invisible: !user.user.tenancies.includes("pcs"),
            url: "/sox/gcp",
          },
          {
            title: "Account Onboarding",
            phase: "BETA",
            iconPack: "source-mid-render-light-icons",
            iconName: "deals",
            url: "/account-onboarding/new-version/gcp",
            invisible: !user.user.tenancies.includes("pcs"),
            disabled: !user.permissions.canReadGcpAnyProject,
            tooltip: user.permissions.canReadGcpAnyProject
              ? "GCP Account Onboarding"
              : "You need /cloud-providers/gcp/accounts or /cloud-providers/gcp/accounts/{AccountId} Read permissions",
          },
        ],
      },
      {
        id: "azure",
        title: "Microsoft Azure",
        icon: "assets/icons-home/azure.svg",
        pages: [
          {
            title: "Cloud Inventory (Subscription Management)",
            iconName: "book",
            iconPack: "source-mid-render-light-icons",
            tooltip: user.permissions.canReadAzureAnySubscription
              ? "View, update & validate your Azure subscriptions"
              : "You need /cloud-providers/azure/subscriptions or /cloud-providers/azure/subscriptions/{subscriptionId} Read permissions",
            disabled: !user.permissions.canReadAzureAnySubscription,
            url: "/dashboard/azure",
          },
          {
            title: "API Explorer",
            tooltip: "View & try out the PCS Azure API & download the API spec",
            disabled: currentTenant !== "vodafone",
            iconName: "community",
            iconPack: "source-mid-render-light-icons",
            url: "/apis/azure-v2",
          },
          {
            title: "Statistics",
            tooltip: "Statistics & charts related to your subscriptions",
            iconName: "chart-line",
            iconPack: "source-mid-render-light-icons",
            disabled: currentTenant !== "vodafone" || !user.permissions.canReadAzureAnySubscription,
            url: "/statistics",
            queryParams: {
              csp: "azure",
              property: "environment",
              chartType: "PieChart",
            },
          },
        ],
      },
      {
        id: "oci",
        title: "Oracle Cloud",
        icon: "assets/icons-home/oracle.svg",
        pages: [
          {
            title: "Cloud Inventory (Compartment Management)",
            iconName: "book",
            iconPack: "source-mid-render-light-icons",
            tooltip: user.permissions.canReadOciAnyCompartment
              ? "View, update & validate your OCI compartments"
              : "You need /cloud-providers/oci/compartments or /cloud-providers/oci/compartments/{CompartmentId} Read permissions",
            disabled: !user.permissions.canReadOciAnyCompartment,
            url: "/dashboard/oci",
          },
          {
            title: "Statistics",
            iconName: "chart-line",
            iconPack: "source-mid-render-light-icons",
            url: "/statistics",
            queryParams: {
              csp: "oci",
              property: "environment",
              chartType: "PieChart",
            },
            disabled: currentTenant !== "vodafone" || !user.permissions.canReadAwsAnyAccount,
            tooltip: "Statistics & charts related to your Account",
          },
          {
            title: "API Explorer",
            iconName: "community",
            iconPack: "source-mid-render-light-icons",
            tooltip: "View & try out the PCS OCI API & download the API spec",
            disabled: currentTenant !== "vodafone",
            url: "/apis/oci-v2",
          },
        ],
      },
      {
        id: "drcc",
        title: "Oracle Cloud DRCC",
        hidden: currentTenant !== "vodafone",
        icon: "assets/icons-home/DRCC.svg",
        pages: [
          {
            title: "Cloud Inventory (Compartment Management)",
            iconName: "book",
            iconPack: "source-mid-render-light-icons",
            tooltip: user.permissions.canReadDrccAnyCompartment
              ? "View, update & validate your DRCC compartments"
              : "You need /cloud-providers/drcc/compartments or /cloud-providers/drcc/compartments/{CompartmentId} Read permissions",
            disabled: !user.permissions.canReadDrccAnyCompartment,
            url: "/dashboard/drcc",
          },
          {
            title: "Statistics",
            iconName: "chart-line",
            iconPack: "source-mid-render-light-icons",
            url: "/statistics",
            queryParams: {
              csp: "drcc",
              property: "environment",
              chartType: "PieChart",
            },
            disabled: !user.permissions.canReadAwsAnyAccount,
            tooltip: "Statistics & charts related to your Account",
          },
          {
            title: "API Explorer",
            iconName: "community",
            iconPack: "source-mid-render-light-icons",
            tooltip: "View & try out the PCS OCI DRCC API & download the API spec",
            disabled: currentTenant !== "vodafone",
            url: "/apis/drcc-v1",
          },
        ],
      },
    ];
  }

  public getTenancyPages(user: UserPermissions): Array<TenancyPage> {
    const currentTenant = user.user.current_tenant ?? localStorage.getItem("tenant") ?? "vodafone";
    return [
      {
        title: "Groups",
        disabled: !user.permissions.canReadAnyTenancy,
        tooltip: user.permissions.canReadAnyTenancy
          ? "View & manage your tenancy groups"
          : "You need /tenancies or /tenancies/{tenancyId} Read permissions",
        url: "/tenancies/groups",
        icon: "assets/icons-home/tenancy-groups.svg",
      },
      {
        title: "Users",
        disabled: !user.permissions.canReadAnyTenancy,
        tooltip: user.permissions.canReadAnyTenancy
          ? "View & manage your tenancy users"
          : "You need /tenancies or /tenancies/{tenancyId} Read permissions",
        url: "/tenancies/users",
        icon: "assets/icons-home/tenancy-users.svg",
      },
      {
        title: "Managed Resources",
        disabled: !user.permissions.canReadAnyTenancy,
        tooltip: user.permissions.canReadAnyTenancy
          ? "View & manage tenancy resources"
          : "You need /tenancies or /tenancies/{tenancyId} Read permissions",
        url: "/tenancies/resources",
        icon: "assets/icons-home/deals.svg",
      },
      {
        title: "Permissions",
        disabled: !user.permissions.canReadAnyTenancy,
        tooltip: user.permissions.canReadAnyTenancy
          ? "View & manage access to your resources"
          : "You need /tenancies or /tenancies/{tenancyId} Read permissions",
        url: "/tenancies/permissions",
        icon: "assets/icons-home/permissions.svg",
      },
      {
        title: "Create New Tenancy",
        disabled: !user.permissions.canCreateTenancies,
        tooltip: user.permissions.canCreateTenancies
          ? "Create a new tenancy for your team / programme"
          : "You need /tenancies Create permissions",
        url: "/tenancies/create",
        icon: "assets/icons-home/create-tenancy.svg",
      },
      {
        title: "Delete a Tenancy",
        disabled: !user.permissions.canDeleteTenancyAny,
        tooltip: user.permissions.canDeleteTenancyAny
          ? "Delete an existing tenancy"
          : "You need /tenancies or /tenancies/{tenancyId} Delete permissions",
        url: "/tenancies/delete",
        icon: "assets/icons-home/delete.svg",
      },
      {
        title: "API Explorer",
        disabled: currentTenant !== "vodafone",
        tooltip: "View & try out the Tenancy API & download the API spec",
        url: "/apis/tenancies-v1",
        icon: "assets/icons-home/apps.svg",
      },
    ];
  }

  public getUser({
    attributes,
    permTypes,
    resourcePrefix,
  }: {
    attributes: Array<string>;
    permTypes: Array<string>;
    resourcePrefix: string;
  }): Observable<UserDetails> {
    const queries: { [q: string]: string } = {
      Attributes: attributes.join(","),
      PermPrefix: resourcePrefix,
      PermTypes: permTypes.join(","),
    };
    return this.get<UserDetails>(
      `tenancies-v1/users/me?${Object.entries(queries)
        .map(([k, v]) => `${k}=${v}`)
        .join("&")}`
    ).pipe(
      tap((u: UserDetails) => {
        u.tenancies = Object.keys(u.groups || {});
        u.email = this.auth.email;
        localStorage.setItem("tenant", u.current_tenant);
        if ((u?.tenancies?.length ?? 0) > 0) {
          const clone: UserDetails = JSON.parse(JSON.stringify(u));
          if (clone?.permissions !== undefined) {
            delete clone.permissions;
          }
          if (clone?.sign_in_events !== undefined) {
            delete clone.sign_in_events;
          }
          if (clone?.groups !== undefined) {
            delete clone.groups;
          }
          localStorage.setItem("userDetails", JSON.stringify(clone));
          localStorage.setItem("allowedTenants", u.allowed_tenants.join(","));
        }
      })
    );
  }
}
